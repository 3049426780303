import RequestAPI from "@/tools/request/v2";

const lang = {
    state: () => ({
        languages: null,
        translations: null,
        selectedLanguage: {
            "lang": "de",
            "name": "Deutsch"
        },
    }),
    // mutations have to be synchronous
    mutations: {
        setLanguages(state, languages) {
            state.languages = languages
        },
        setTranslations(state, translations) {
            state.translations = translations
        },
        setSelectedLanguage(state, language) {
            state.selectedLanguage = language
        },
    },
    // actions can be async - we can call the API Backend here
    actions: {
        fetchLanguages(context) {
            RequestAPI('get', "public/languages", "").then(r => {
                if (r.data) {
                    context.commit("setLanguages", r.data)
                }
            })
            context.dispatch("fetchTranslations")
        },
        fetchTranslations(context) {
            RequestAPI('get', `public/translations/${context.state.selectedLanguage.lang}`, "").then(r => {
                if (r.data) {
                    context.commit("setTranslations", r.data)
                }
            })
        },
        setSelectedLanguage(context, language) {
            context.commit("setSelectedLanguage", language)
            context.dispatch("fetchTranslations")
        }
    },
    getters: {
        languageList(state) {
            if (state.languages) return Object.values(state.languages)
        },
        t: (state) => (phrase) => {
            if (state.translations) {
                const found = state.translations.phrases[phrase];
                if (found) return found
                // else console.log("phrase not found: " + phrase)
            }

            return phrase
        },
        getSelectedLanguage: (state) =>{
            return state.selectedLanguage
        }
    }
}

export default lang
