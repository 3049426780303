
<v-footer
    absolute
    color="#00303B"
    class="text-center"
>
  <v-card-text>
    <v-btn
        text
        small
        color="white"
        depressed
        href="https://www.netpoint-media.de/inside/impressum"
        target="_blank"
    >{{ $t('footer-imprint') }}
    </v-btn>
    <v-btn
        text
        small
        color="white"
        depressed
        @click="$router.push('/privacy-policy')"
        target="_blank"
    >{{ $t('footer-data-safety') }}
    </v-btn>

  </v-card-text>
</v-footer>
