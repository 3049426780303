import RequestAPI from "@/tools/request/v2";
import {arrayToObj} from "@/tools/acrobatics";

const partner = {
    state: () => ({
        partner: {
            body: [
            ],
            header: []
        },
        jca: {
            body: [
            ],
            header: [],
            extraHeader: [],
            extraHeaderColspans: [10, 9, 2, 3, 4]
        },
        special: {
            body: [
            ],
            header: []
        },

    }),
    mutations: {
        setPartners(state, payload){
            //RESET STATE
            state.partner = {
                body: [
                ],
                header: []
            }
            state.jca = {
                body: [
                ],
                header: [],
                extraHeader: [],
                extraHeaderColspans: [10, 9, 2, 3, 4]
            }
            state.special = {
                body: [
                ],
                header: []
            }



            const partnerHeader = JSON.parse(payload[0].header)
            state.partner.body.push(partnerHeader)
            state.partner.header = partnerHeader.map((item, index) => {
                return {value: item, text: item}
            })

            JSON.parse(payload[0].body).forEach((item, index) => {
                state.partner.body.push(item)
            });
            state.partner.body = arrayToObj(state.partner.body)





            const jcaHeader = JSON.parse(payload[1].header)[1]
            state.jca.body.push(jcaHeader)
            state.jca.header = jcaHeader.map((item, index) => {
                return {value: item, text: item}
            })

            state.partner.body.push(JSON.parse(payload[1].header))
            JSON.parse(payload[1].body).forEach((item, index) => {
                state.jca.body.push(item)
            });
            state.jca.body = arrayToObj(state.jca.body)
            state.jca.extraHeader = JSON.parse(payload[1].header)[0]

            // let prev = 0;
            // state.jca.extraHeaderColspans = state.jca.extraHeader.map((item, index) => {
            //     if (item === null){
            //         return 0
            //     }
            //     const value = index - prev
            //     prev = value
            //     return value
            // })




            const specialHeader = JSON.parse(payload[2].header)
            state.special.body.push(specialHeader)
            state.special.header = specialHeader.map((item, index) => {
                return {value: item, text: item}
            })

            JSON.parse(payload[2].body).forEach((item, index) => {
                state.special.body.push(item)
            });
            state.special.body = arrayToObj(state.special.body)
        },
    },
    actions: {
        fetchPartnersRaw(context){
            RequestAPI('get', "vendors", "", true).then(r => {
                if (r.data) {
                    context.commit("setPartners", r.data)
                }
            })
        },
    },
    getters: {
        getPartner: state => state.partner,
        getJca: state => state.jca,
        getSpecial: state => state.special,
    }
}

export default partner
