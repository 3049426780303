import RequestAPI from "@/tools/request/v2";

const misc = {
    state: () => ({
        adsTxts: [],
        adsTxtError: null,
        documents: [],
        violations: {},
        account: [],
        documentDownloads: [],
        inviteCode: null,
        currentTourStep: 0,
        completedTour: false,
        logging: null
    }),
    mutations: {
        setLogging(state, payload){
            state.logging = payload
        },
        incrementTourStep(state){
            state.currentTourStep++;
        },
        completeTour(state){
            state.completedTour = true;
        },
        startTour(state){
            state.currentTourStep = 0;
            state.completedTour = false;
        },

        setAdsTxts(state, adsTxts) {
            state.adsTxts = adsTxts
        },
        setInviteCode(state, inviteCode) {
            state.inviteCode = inviteCode
        },
        setAdsTxtForDomain(state, payload) {
            // state.adsTxts.splice(state.adsTxts.indexOf(state.adsTxts.find(entry => entry.domain === payload.domain), 1))
            // state.adsTxts.push(payload.entries)

            const index = state.adsTxts.findIndex(adsTxt => adsTxt.id === payload.domain)
            state.adsTxts[index].customAdsTxt = payload.entries
        },
        removeAdsTxtForDomain(state, payload) {
            // state.adsTxts.splice(state.adsTxts.indexOf(state.adsTxts.find(entry => entry.domain === payload.domain), 1))
            // state.adsTxts.push(payload.entries)

            const index = state.adsTxts.findIndex(adsTxt => adsTxt.id === payload.domain)
            state.adsTxts[index].customAdsTxt = payload.entries
        },
        setDocuments(state, documents) {
            state.documents = documents
        },
        setViolations(state, violations) {
            state.violations = violations
        },
        setAccount(state, account) {
            state.account = account
        },
        addDocument(state, document) {
            state.documents.push(document)
        },
        setDomainsAdsTxt(state, domains) {
            state.adsTxts = domains.map(domain => {
                return {
                    domain: domain.name, customAdsTxt: null
                }
            })
        },
        setAdsTxtError(state, error) {
            state.adsTxtError = error
        }


    },
    actions: {
        async fetchAdsTxt(context) {

            const response = await RequestAPI('get', "dashboard", "", true)
            if (response) {
                const adsTxts = response.data.domains.map(async (domain) => {
                    let customAdsTxt = await RequestAPI('get', "adstxt/" + domain.id, "", true)
                    return {
                        name: domain.name, id: domain.id, customAdsTxt: customAdsTxt.data
                    }
                })
                context.commit("setAdsTxts", await Promise.all(adsTxts))
            }


            // RequestAPI('get', "dashboard", "", true).then(async r => {
            //     if (r.data) {
            //         let adsTxts = r.data.map(async (domain) => {
            //             let customAdsTxt = await RequestAPI('get', "adstxt/" + domain.id, "", true)
            //             return {
            //                 name: domain.name, id: domain.id, customAdsTxt: customAdsTxt.data
            //             }
            //         })
            //         context.commit("setAdsTxts", await Promise.all(adsTxts))
            //     }
            // })
        },
        fetchLogging(context) {
            RequestAPI('get', "logging", "", true).then(async r => {
                if (r.data) {
                    context.commit("setLogging", (r.data))
                }
            })
        },
        fetchAdsTxtForDomain(context, domainId) {
            RequestAPI('get', "adstxt/" + domainId, "", true).then(async r => {
                if (r.data) {
                    // const index = context.state.adsTxts.findIndex(adsTxt => adsTxt.id === domainId)
                    // context.state.adsTxts[index] = r.data

                    console.log(r.data)
                    context.commit("setAdsTxtForDomain", {domain: domainId, entries: r.data})
                }
            })
        },
        syncNewAdsTxtBlock(context, payload) {
            const domainId = payload.domainId
            const data = {
                name: payload.name,
                adstxt: payload.entries
            }
            RequestAPI('post', "adstxt/" + domainId, data, true).then(async r => {
                await context.dispatch("fetchAdsTxtForDomain", domainId)
                console.log("syncNewAdsTxtBlock")
                console.log(r)
                if (r) {
                    await context.dispatch("fetchAdsTxtForDomain", domainId)
                    if (r.error) {
                        context.commit("setAdsTxtError", true)
                    } else {
                        await context.dispatch("fetchAdsTxtForDomain", domainId)
                    }
                } else {
                    context.commit("setAdsTxtError", true)
                }
            })
        },
        syncAdsTxtBlock(context, payload) {
            const domainId = payload.domainId
            const blockId = payload.blockId
            const data = {
                name: payload.name,
                adstxt: payload.entries
            }
            RequestAPI('put', "adstxt/" + domainId + "/" + blockId, data, true).then(async r => {

                if (r) {
                    if (r.error) {
                        context.commit("setAdsTxtError", true)
                    } else {
                        await context.dispatch("fetchAdsTxtForDomain", domainId)
                    }
                } else {
                    context.commit("setAdsTxtError", true)
                }
            })
        },
        deleteAdsTxtBlock(context, payload) {
            const domainId = payload.domainId
            const blockId = payload.blockId
            const data = {
                delete: true,
            }
            RequestAPI('put', "adstxt/" + domainId + "/" + blockId, data, true).then(async r => {
                await context.dispatch("fetchAdsTxtForDomain", domainId)
            })
        },
        redeemInvitiation(context) {
            const data = {
                hash: context.state.inviteCode
            }
            RequestAPI('post', "invitation", data, true).then(async r => {
                if (r) {
                    context.commit("setInviteCode", null)
                }
            })
        },
        fetchDocuments(context) {
            RequestAPI('get', "documents", "", true).then(r => {
                if (r.data) {
                    context.commit("setDocuments", r.data)
                }
            })
        },
        fetchViolations(context) {
            RequestAPI('get', "violations", "", true).then(r => {
                if (r.data) {
                    context.commit("setViolations", r.data)
                }
            })
        },
        fetchAccount(context) {
            RequestAPI('get', "settings", "", true).then(r => {
                if (r.data) {
                    context.commit("setAccount", r.data)
                }
            })
        },
        fetchDocument(context, documentId) {
            RequestAPI('get', "documents/" + documentId, "", true, true).then(r => {
                if (r) {
                    // console.log(r)
                    const blob = new Blob([r.data], {type: 'application/pdf'})
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(r)
                    link.download = documentId + ".pdf"
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(link.href)
                    document.body.removeChild(link)
                }
            })
        },
    },
    getters: {
        getCurrentTourStep(state){
            return state.currentTourStep
        },
        getCompletedTour(state){
            return state.completedTour
        }
    }
}

export default misc
