<template>
  <div>
    <v-card>
      <v-navigation-drawer
          app
          class="sidebar"
          right
          v-model="rightDrawer"
          width="22em"
          color="primary"
          dark
      >
        <v-sheet class="gradient pa-2 mb-2">
          <div class="px-4">
            <np-date-picker dark v-model="selectedTimeframe" :timerange-presets="$store.getters.getTimeframePresets" @input="$store.dispatch('uploadFilter')"></np-date-picker>
          </div>
          <v-divider class="my-4"></v-divider>
          <v-checkbox hide-details :disabled="!sidebarReady" class="px-4" @click="compareChartToggle" v-model="compareChartEnabled" :label="$t('enable-compare-chart')"></v-checkbox>
          <v-checkbox hide-details  v-if="$store.getters.userHasReportAccess" :disabled="!sidebarReady" class="px-4" v-model="sourceCompareChartEnabled" :label="$t('enable-source-compare-chart')"></v-checkbox>

          <v-divider class="my-4"></v-divider>
          <v-icon class="pl-5">mdi-list-status</v-icon>
          <v-btn-toggle
              :disabled="!sidebarReady"
              class="ml-2 "
              v-model="sidebarMetricSelects"
              rounded
              dense
              multiple
              id="t_split"
          >

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn

                    color="#00303b"
                    v-on="on"
                    v-bind="attrs"
                    @input="afterMetricChange"
                >
                  <v-icon>mdi-web</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('sidebar-tooltip-show-websites') }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="#00303b"
                    v-on="on"
                    v-bind="attrs"
                    @input="afterMetricChange"
                >
                  <v-icon>mdi-cellphone-link</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('sidebar-tooltip-show-devices') }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="#00303b"
                    v-on="on"
                    v-bind="attrs"
                    @input="afterMetricChange"
                >
                  <v-icon>mdi-tab-unselected</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('sidebar-tooltip-show-adunits') }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="#00303b"
                    v-on="on"
                    v-bind="attrs"
                    @input="afterMetricChange"
                >
                  <v-icon>mdi-details</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('sidebar-tooltip-show-campaigns') }}</span>
            </v-tooltip>


          </v-btn-toggle>
          <v-divider class="my-4"></v-divider>
          <v-select hide-details :disabled="!sidebarReady" class="px-4" color="#00303B" prepend-icon="mdi-image-size-select-small" dense rounded flat solo-inverted :label="$t('granularity-selector')" :items="granularities" v-model="selectedGranularity"></v-select>
          <v-divider class="my-4"></v-divider>
          <!--          <v-autocomplete-->
<!--              id="t_timesel"-->
<!--              dark-->
<!--              class="mt-12 mb-2"-->
<!--              v-model="timeframeValue"-->
<!--              :items="$store.getters.getTimeframeSelectItems"-->
<!--              outlined-->
<!--              hide-details-->
<!--              dense-->
<!--              :label="this.$t('label-timeframe')"-->
<!--              @input="$store.dispatch('uploadFilter')"-->
<!--          >-->

<!--          </v-autocomplete>-->





<!--          <v-text-field-->
<!--              dense-->
<!--              v-model="search"-->
<!--              :label="this.$t('label-search')"-->
<!--              dark-->
<!--              rounded-->
<!--              flat-->
<!--              solo-inverted-->
<!--              hide-details-->
<!--              prepend-icon="mdi-magnify"-->
<!--              class="px-4"-->
<!--          ></v-text-field>-->

<!--          <v-tooltip top>-->
<!--            <template v-slot:activator="{ on, attrs }">-->
<!--              <v-btn-->
<!--                  icon-->
<!--                  class="ml-5"-->
<!--                  v-on="on"-->
<!--                  v-bind="attrs"-->
<!--                  @click="treeviewOpen = []"-->
<!--              >-->
<!--                <v-icon>mdi-arrow-u-up-right-bold</v-icon>-->
<!--              </v-btn>-->
<!--            </template>-->
<!--            <span>{{ $t('sidebar-close-all') }}</span>-->
<!--          </v-tooltip>-->


          <v-row no-gutters>
            <v-col cols="10">
              <v-text-field
                  dense
                  v-model="search"
                  :label="this.$t('label-search')"
                  dark
                  rounded
                  flat
                  solo-inverted
                  hide-details
                  prepend-icon="mdi-magnify"
                  class="pl-5"
              ></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon

                      v-on="on"
                      v-bind="attrs"
                      @click="treeviewOpen = []"
                  >
                    <v-icon>mdi-arrow-u-up-right-bold</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('sidebar-close-all') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>









        <v-treeview
            class="pt-1"
            color="primary"
            selected-color="primary lighten-2"
            id="t_filter"
            v-if="$store.state.statistics.dashboardReady"
            :open="treeviewOpen"
            open-on-click
            v-model="sidebarFilterSelects"
            dense
            :items="filterItems"
            selectable
            :search="search"

        >
        </v-treeview>

        </v-sheet>
        <v-skeleton-loader
            v-show="!$store.state.statistics.dashboardReady"
            class="mx-auto"
            max-width="300"
            height="800"
            type="image@3"
        ></v-skeleton-loader>


      </v-navigation-drawer>
    </v-card>
    <div class="text-center">
      <v-dialog
          v-model="customDateDialog"
          width="500"
      >

        <v-card class="pa-4">
          <v-card-title>
            {{ $t('sidebar-custom-date-title') }}
          </v-card-title>

          <v-date-picker
              no-title
              full-width
              v-model="customTimeframe"
              :first-day-of-week="1"
              range
              :max="moment().format('YYYY-MM-DD')"
          ></v-date-picker>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="applyCustomTimeframe()"
            >
              Apply
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-container v-show="!boxSelected">
      <v-alert type="warning">{{ $t("error-dashboard-no-dimension-selected") }}</v-alert>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import NpDatePicker from "@/components/np-date-picker.vue";
import {objectMap} from "@/tools/acrobatics";

export default {
  name: "np-sidebar",
  components: {NpDatePicker},
  props: {
    rightDrawer: Boolean,
  },
  data() {
    return {
      test: null,
      search: "",
      customDateDialog: false,
      treeviewOpen: ['adunits'],
      moment: moment,
      timeframeValueTest: null,
      granularities: [
        { text: this.$t("granularity-day"), value: "day" },
        { text: this.$t("granularity-week"), value: "yearweek" },
        { text: this.$t("granularity-month"), value: "yearmonth" },
        { text: this.$t("granularity-year"), value: "year" },
      ],
    }
  },
  mounted() {
    if (!this.boxSelected && this.filterItems[0].children.length === 0) {
      this.$store.dispatch("fetchDomains")
    }
  },
  methods: {
    applyCustomTimeframe() {
      this.customDateDialog = false
        // console.log("applyCustomTimeframe")
      this.$store.dispatch("uploadFilter")
      this.$store.dispatch("fetchReport")
    },
    afterMetricChange() {
        // console.log("afterMetricChange")
      this.$store.dispatch("uploadFilter")
      this.$store.dispatch("fetchReport")
    },
    compareChartToggle() {
      if (Object.keys(this.$store.getters.getCompareChartTimeframe).length === 0){
        let deference =moment.duration( moment(this.selectedTimeframe.end).diff(moment(this.selectedTimeframe.start)))
        let end = moment(this.selectedTimeframe.start).subtract(1, "days").format('YYYY-MM-DD');
        let start = moment(this.selectedTimeframe.start).subtract((deference.asDays() + 1), "days").format('YYYY-MM-DD');
        let timeframe = {
          base: {start: this.selectedTimeframe.start,end: this.selectedTimeframe.end},
          compare: {start: start,end: end}
        }
        this.$store.commit("setCompareChartTimeframe", timeframe)
      }

        this.$store.dispatch('fetchCompare', this.$store.getters.getCompareChartTimeframe)
        // console.log("compareChartToggle")
        this.$store.dispatch('uploadFilter')


      if (this.compareChartEnabled){
        this.$emit('compare-toggle', true)
      }

    }



  },
  computed: {
    sidebarReady() {
      const i = this.$store.getters.getMetricSimple('i')
      if (i === undefined) {
        return false
      }
      if (i.length === 0) {
        return false
      }
      return true
    },
    sidebarFilterSelects: {
      get() {
        return this.$store.state.statistics.sidebarFilterSelects
      },
      set(value) {
        this.$store.commit("setSidebarFilterSelects", value)
        this.$store.dispatch("fetchReport")
      }
    },
    selectedGranularity: {
      get() {
        return this.$store.state.statistics.selectedGranularity
      },
      set(value) {
        this.$store.commit("setSelectedGranularity", value)
        this.$store.dispatch("fetchReport")
      }
    },
    compareChartEnabled: {
      get() {
        return this.$store.getters.getShowCompareChart
      },
      set(bool) {
        this.$store.commit("setShowCompareChart", bool)
      }
    },
    sourceCompareChartEnabled: {
      get() {
        return this.$store.getters.getShowSourceCompareChart
      },
      set(bool) {
        this.$store.commit("setShowSourceCompareChart", bool)
      }
    },
    sidebarMetricSelects: {
      get() {
        return this.$store.state.statistics.sidebarMetricSelects
      },
      set(value) {
        this.$store.commit("setSidebarMetricSelects", value)
      }
    },
    timeframeValue: {
      get() {
        return this.$store.state.statistics.timeframe.value
      },
      set(value) {
        this.$store.commit("setTimeframeValue", value)
        if (value === "custom") {
          this.customDateDialog = true
        } else {
          this.$store.dispatch("fetchReport")
        }
      }
    },
    selectedTimeframe: {
      get() {
        return this.$store.getters.getSelectedTimeframe
      },
      set(value) {
        this.$store.commit("setSelectedTimeframe", value)
        this.$store.dispatch("fetchReport")
      }
    },
    timeframe: {
      get() {
        return this.$store.state.statistics.timeframe
      }
    },
    customTimeframe: {
      get() {
        return this.$store.state.statistics.customTimeframe
      },
      set(value) {
        this.$store.commit("setCustomTimeframe", value)
      }
    },

    filterItems() {
      let items = []


      items.push({
        id: "devices",
        name: "Devices",
        icon: "mdi-cellphone-link",
        children: this.$store.state.statistics.deviceFilter.map(device => {
          return {
            id: device.name,
            name: device.name
          }
        })
      });
      items.push({
        id: "adunits",
        name: "Websites >> Adunits",
        icon: "mdi-tab-unselected",
        children: this.$store.state.statistics.domains.map(domain => {
          return {name: domain.name, id: "adunits_" + domain.id, children: domain.adunits}
        })
      });

      const sources = [];

      for (const sourceKey in this.$store.state.statistics.sources) {
        const campaigns = [];
        const campaignsData = this.$store.state.statistics.sources[sourceKey];

        for (const campaignKey in campaignsData) {
          campaigns.push({ name: campaignsData[campaignKey], id: campaignKey });
        }

        sources.push({ name: sourceKey, id: sourceKey, children: campaigns });
      }

      // console.log("sources", sources);

      items.push({
        id: "sources",
        name: this.$store.getters.t("sidebar-filter-sources"),
        icon: "mdi-tab-unselected",
        children: sources
      })


      // items.push({
      //   id: "websites",
      //   name: "Websites",
      //   icon: "mdi-web",
      //   children: this.$store.getters.domainFilter.map(domain => {
      //     return {
      //       id: domain.domainId,
      //       name: domain.name
      //     }
      //   })
      // });

      // if (items.length > 0) {
      //   this.$store.commit("setSidebarMaxFilters", items.reduce((acc, item) => {
      //     console.log(item.children)
      //     if (item.children.children){
      //       return acc + item.children.reduce((acc, item) => {
      //         return acc + item.children.length
      //       }, 0)
      //     } else {
      //       return acc + item.children.length
      //     }
      //   }, 0))
      // }


      return items

    },
    domainList() {
      return this.$store.state.statistics.domains.map(domain => {
        return {text: domain.name, value: domain.id}
      })
    },
    adunitItems() {
      return [{
        name: "Adunits",
        icon: "mdi-tab-unselected",
        id: "adunits",
        children: this.$store.state.statistics.domains.map(domain => {
          return {name: domain.name, id: domain.id, children: domain.adunits}
        })
      }]

    },
    boxSelected() {
      // const domainSelected = this.filterItems.find(item => item.id === "websites").children.some(item => {
      //   return this.$store.state.statistics.sidebarFilterSelects.includes(item.id)
      // })

      const devicesSelected = this.filterItems.find(item => item.id === "devices").children.some(item => {
        return this.$store.state.statistics.sidebarFilterSelects.includes(item.id)
      })

      let adunitsSelected = false;
      for (let item of this.filterItems.find(item => item.id === "adunits").children) {
        if (adunitsSelected === false){
          adunitsSelected = item.children.some(subItem => {
            return this.$store.state.statistics.sidebarFilterSelects.includes(subItem.id)
          })
        }
      }
      if (!this.$store.state.statistics.noAccountLink && this.$store.state.statistics.dashboardReady) {
        this.$store.commit("setSidebarDimensionsSelected", devicesSelected && adunitsSelected)
        return devicesSelected && adunitsSelected
      }
      this.$store.commit("setSidebarDimensionsSelected", false)


      return false
    }
  }
}
</script>

<style scoped>
.sidebar {
  z-index: 1;
  padding-top: 4em;

}

.gradient {
  background: linear-gradient(to bottom, #00303b, #00586c);
}
</style>
