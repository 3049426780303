<template>
  <v-menu

      :disabled="disabled"
      ref="dateMenu"
      v-model="dialog"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-x
      left
      min-width="auto"
      v-if="timerangePresets.length > 0"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          :dark="dark"
          flat
          solo-inverted
          single-line

          dense
          :label="$t('reports-timeframe-label')"
          v-model="dateDisplay"
          prepend-icon="mdi-calendar"
          readonly
          hide-details
          v-bind="attrs"
          v-on="on"
          rounded

      ></v-text-field>
    </template>

    <v-card flat max-width="40em">
      <v-row>
        <v-col class="mt-3 ml-3" >
          <v-card-title>
            {{title}}
          </v-card-title>
          <v-card-subtitle>
            {{subtitle}}
          </v-card-subtitle>
          <v-date-picker
              full-width
              no-title
              v-model="selectedTimerange"
              range
              @input="selectedPreset = null"
              show-adjacent-months
              :first-day-of-week="1"
              color="primary"
              class="mt-5"

          >

          </v-date-picker>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="5" >
          <v-card flat class="overflow-auto preset-bar-vertical">

            <v-list-item-group
                class="pt-5"
                v-model="selectedPreset"
            >
              <v-list-item
                  v-for="preset in timerangePresets"
                  :key="preset.name"
                  dense
                  color="primary"

              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{$t(preset.name)}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0">
          <v-divider></v-divider>
        </v-col>

      </v-row>



      <v-card-actions>

        <v-spacer></v-spacer>
        <v-btn
            text
            color="secondary"
            @click="dialog = false"
        >

          {{$t('button-cancel')}}
        </v-btn>


        <v-btn
            text
            color="primary"
            @click="apply"
            :disabled="!timeframeValid"
        >
          {{$t('button-ok')}}
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import moment from "moment/moment";

export default {
  name: "np-date-picker",
  data() {
    return {
      dialog: false,
      selectedPreset: null,
      selectedTimerange: null,
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    timerangePresets: {
      type: Array,
      default: () => []
    },
    maxDays: {
      type: Number,
      default: 5000,
    },
    value: {
      start: {
        type: String,
        default: null,
      },
      end: {
        type: String,
        default: null,
      },
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$nextTick(() => {
      if(this.value.start && this.value.end) {
        this.selectedTimerange = [this.value.start, this.value.end];
      } else {
        this.selectedPreset = 1;
        let asd = this.timeframe
        this.apply()
      }
    });
  },
  watch: {
    value: {
      handler: function (val) {
        if(val.start && val.end) {
          this.selectedTimerange = [val.start, val.end];
        } else {
          this.selectedPreset = 1;
          let asd = this.timeframe
          this.apply()
        }
      },
      deep: true,
    },
    selectedTimerange: {
      handler(newValue, oldValue) {
        this.rearrangeTimeframe()
      },
      deep: true
    }
  },
  methods: {
    apply() {
      this.$emit('input', {start: this.selectedTimerange[0], end: this.selectedTimerange[1]})
      this.dialog = false
    },
    rearrangeTimeframe(){
      if (this.selectedTimerange?.length === 2)
        if (this.selectedTimerange[0] > this.selectedTimerange[1]){
          const bigger = this.selectedTimerange[0]
          const smaller = this.selectedTimerange[1]

          this.selectedTimerange[0] = smaller
          this.selectedTimerange[1] = bigger
        }
    }
  },
  computed: {
    timeframeValid(){
      const valid = (this.maxDays > this.timeframeInDays) && this.timeframeInDays > 0
      this.$emit('valid', valid)
      return valid
    },
    timeframeInDays(){
      if (!this.timeframe) return 0
      if (!this.timeframe.start || ! this.timeframe.end) return 0
      const b = moment(this.timeframe.start)
      const a = moment(this.timeframe.end)

      return a.diff(b, "days") + 1
    },
    preset(){
      return this.timerangePresets[this.selectedPreset]
    },
    title(){
      return this.dateDisplay.split("(")[0] || this.$t("date-picker-selecting")
    },
    subtitle(){
      if (this.preset) return this.dateDisplay.split("(")[1].replace(")", "") + " (" + this.timeframeInDays + " " + this.$t("days") + ")"
      return " (" + this.timeframeInDays + " " + this.$t("days") + ")"
    },
    timeframe(){
      if(this.selectedPreset !== null && this.selectedPreset !== undefined){
        // if(this.selectedTimerange[0] === null || this.selectedTimerange[1] === null){
        //   return
        // }

        const preset = this.timerangePresets[this.selectedPreset]

        moment.updateLocale("en", {
          week: {
            dow: 1, // First day of week is Monday
            doy: 4  // First week of year must contain 4 January (7 + 1 - 4)
          }
        })


        if (preset.source === 'YESTERDAY'){
          const start = moment().subtract(1, "days").format("YYYY-MM-DD")
          const end = moment().subtract(1, "days").format("YYYY-MM-DD")
          this.selectedTimerange = [start, end]
          // this.$emit("input", {start, end})
          return {start, end}
        }

        if (preset.source === 'LAST_WEEK'){
          const start = moment().subtract(1, "week").startOf("week").format("YYYY-MM-DD")
          const end = moment().subtract(1, "week").endOf("week").format("YYYY-MM-DD")
          this.selectedTimerange = [start, end]
          // this.$emit("input", {start, end})
          return {start, end}
        }

        if (preset.source === 'LAST_7_DAYS'){
          const start = moment().subtract(7, "days").format("YYYY-MM-DD")
          const end = moment().subtract(1, "days").format("YYYY-MM-DD")
          this.selectedTimerange = [start, end]
          return {start, end}
        }

        if (preset.source === 'THIS_WEEK'){
          const start = moment().startOf("week").format("YYYY-MM-DD")
          let end = moment().endOf("week")
          if (end > moment().subtract(1)) end = moment().subtract(1, "days")
          end = end.format("YYYY-MM-DD")
          this.selectedTimerange = [start, end]
          // this.$emit("input", {start, end})
          return {start, end}
        }

        if (preset.source === 'LAST_MONTH'){
          const start = moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD")
          const end = moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
          this.selectedTimerange = [start, end]
          // this.$emit("input", {start, end})
          return {start, end}
        }

        if (preset.source === 'LAST_30_DAYS'){
          const start = moment().subtract(30, "days").format("YYYY-MM-DD")
          const end = moment().subtract(1, "days").format("YYYY-MM-DD")
          this.selectedTimerange = [start, end]
          return {start, end}
        }

        if (preset.source === 'THIS_MONTH'){
          const start = moment().startOf("month").format("YYYY-MM-DD")
          let end = moment().endOf("month")
          if (end > moment().subtract(1)) end = moment().subtract(1, "days")
          end = end.format("YYYY-MM-DD")
          this.selectedTimerange = [start, end]
          // this.$emit("input", {start, end})
          return {start, end}
        }

        if (preset.source === 'LAST_90_DAYS'){
          const start = moment().subtract(90, "days").format("YYYY-MM-DD")
          const end = moment().subtract(1, "days").format("YYYY-MM-DD")
          this.selectedTimerange = [start, end]
          return {start, end}
        }

        if (preset.source === 'LAST_YEAR'){
          const start = moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD")
          const end = moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD")
          this.selectedTimerange = [start, end]
          // this.$emit("input", {start, end})
          return {start, end}
        }

        if (preset.source === 'THIS_YEAR'){
          const start = moment().startOf("year").format("YYYY-MM-DD")
          let end = moment().endOf("year")
          if (end > moment().subtract(1)) end = moment().subtract(1, "days")
          end = end.format("YYYY-MM-DD")
          this.selectedTimerange = [start, end]
          // this.$emit("input", {start, end})
          return {start, end}
        }



      }
      if (this.selectedTimerange) {
        // this.$emit("input", {start: this.selectedTimerange[0], end: this.selectedTimerange[1]})
        return {start: this.selectedTimerange[0], end: this.selectedTimerange[1]}
      }
    },
    dateDisplay(){
      if (!this.timeframe) return ""
      if (!this.timeframe.start || !this.timeframe.end) return ""
      if (this.preset) return this.$t(this.preset.name) + " (" + moment(this.timeframe.start).format("DD.MM.YYYY")  + " ~ " + moment(this.timeframe.end).format("DD.MM.YYYY") + ")"
      return moment(this.timeframe.start).format("DD.MM.YYYY") + " ~ " + moment(this.timeframe.end).format("DD.MM.YYYY")

    },
  }
}
</script>

<style scoped>
  .preset-bar-vertical {

  }

</style>
