import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import lang from "@/store/modules/lang";
import auth from "@/store/modules/auth";
import * as localforage from "localforage";
import settings from "@/store/modules/settings";
import communication from "@/store/modules/communication";
import misc from "@/store/modules/misc";
import statistics from "@/store/modules/statistics";
import reports from "@/store/modules/reports";
import partner from "@/store/modules/partner";

export const localForageService = localforage.createInstance({
    name: "pubzone",
    version: 1.0,
    storeName: 'np-store'
});

localForageService.setDriver([
    localforage.INDEXEDDB,
])
    .then(() => {
        // use this to test your db connection - delete later
        localForageService.setItem('testkey', 'testvalue', function () {
            console.log('Of the driver options given, localforage is using:' + localForageService.driver());
        });
    })
    .catch(error => {
        // welp. you can't have nice things.
    });

Vue.use(Vuex)

// init localStorage store integration
const vuexLocal = new VuexPersistence({
    key: "np-store",
    storage: window.localStorage,
    modules: ["lang", "settings"],
})

// Store is split up in "modules"
export default new Vuex.Store({
    modules: {
        lang: lang,
        auth: auth,
        settings: settings,
        communication: communication,
        misc: misc,
        statistics: statistics,
        reports: reports,
        partner: partner,
    },
    plugins: [vuexLocal.plugin]
})
