
<div>
  <v-card>
    <v-card-title>{{ $t('notifications-heading') }}</v-card-title>
    <v-list>
      <v-divider></v-divider>
      <div :key="notification.id" v-for="notification in $store.state.communication.notifications">
        <v-list-item @click="openDialog(notification)">
          <v-list-item-icon>
            <v-icon color="primary">{{ notification.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ notification.title }}</v-list-item-title>
          <v-list-item-subtitle>{{ notification.text }}</v-list-item-subtitle>
          <v-list-item-action>
            <v-btn icon @click="archiveNotification(notification)">
              <v-icon color="red">mdi-delete-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </div>


    </v-list>
  </v-card>

  <v-dialog
      v-if="activeNotification"
      v-model="dialog"
      width="500"
  >
    <v-card>
      <v-card-title>
        <v-icon color="primary" class="mr-2">{{ activeNotification.icon }}</v-icon>
        {{ activeNotification.title }}
      </v-card-title>

      <v-card-text>
        {{ activeNotification.text }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            v-if="activeNotification.url"
            color="primary"
            text
            @click="openUrl(activeNotification.url)"
        >
          open url
        </v-btn>
        <v-btn
            color="red"
            text
            @click="archiveNotification(activeNotification)"
        >
          delete
        </v-btn>
        <v-btn
            color="secondary"
            text
            @click="dialog = false"
        >
          close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
