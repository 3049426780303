import chroma from "chroma-js";
import store from '/src/store'

const objectMap = (obj, fn) =>
    Object.fromEntries(
        Object.entries(obj).map(
            ([k, v], i) => [k, fn(v, k, i)]
        )
    )

function objectsEqual(o1, o2) {
    return Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(p => o1[p] === o2[p]);
}

function arraysEqual(a1, a2) {
    return a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));
}


function formatNumber(number) {
    return Math.round(number * 100) / 100
}

function formatNumberAsString(number) {
    const locales = { de: "de-DE", en: "en-US"}
    return number.toLocaleString(locales[store.getters.getSelectedLanguage.lang], {minimumFractionDigits: 0, maximumFractionDigits: 2})
}

function formatCurrency(number) {
    const locales = { de: "de-DE", en: "en-US"}

    let string = (Math.round(number * 100) / 100).toLocaleString(locales[store.getters.getSelectedLanguage.lang], {minimumFractionDigits: 2})
    return string + " €"
    // if (string){
    //     if (string.split(',')[1].length <= 1) string += "0"
    //     return string + " €"
    // } 
}

function renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map(key => {
        // console.log(newKeys, newKeys[key], key)
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
}

function calculateCPM(i, r) {
    return Math.round((r / i) * 1000 * 100) / 100
}

function spreadColors(color1, color2, count, colorScale){

    return chroma.scale([color1, color2]).classes(colorScale).colors(count);
}

const arrayToObj = ([keys, ...values]) =>
    values.map(vs => vs.reduce((acc, v, i) => (acc[keys[i]] = v, acc), {}))








export {formatNumber, formatCurrency, objectMap, calculateCPM, spreadColors, objectsEqual, arraysEqual, renameKeys, formatNumberAsString, arrayToObj}
