import Vue from 'vue'
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";


// We initialize the firebase SDK and export the auth part. If we wanted to use other functions from firebase we might
// want a "firebase" directory instead of this "auth" directory.
/*const firebaseConfig = {
    apiKey: "AIzaSyAhrJUU8sHKaWPjj9sZ0QNyVWFWeEO0HgI",
    authDomain: "yournp-18995.firebaseapp.com",
    projectId: "yournp-18995",
    storageBucket: "yournp-18995.appspot.com",
    messagingSenderId: "449749692644",
    appId: "1:449749692644:web:5e1b5f7fc595312193bcf1",
    measurementId: "G-XF4EXFLPKQ"
};*/

const firebaseConfig = {
    apiKey: "AIzaSyBcPguMn1nYjukWChKKtcbmTgq-Ar5Gl80",
    authDomain: "publisherzone-a0f04.firebaseapp.com",
    projectId: "publisherzone-a0f04",
    storageBucket: "publisherzone-a0f04.appspot.com",
    messagingSenderId: "414027871033",
    appId: "1:414027871033:web:b217e7ed9bea5da9dc2ee8",
    measurementId: "G-8QHQLW678Z"
};


firebase.initializeApp(firebaseConfig);

// export const messaging = firebase.messaging();


const auth = firebase.auth()
export default auth
