import Vuetify from '../../plugins/vuetify'
import RequestAPI from "@/tools/request/v2";

const settings = {
    state: () => ({
        notifications: [],
        messages: [],
        recipients: [],
        threads: [],
        notificationTokens: []
    }),
    mutations: {
        setNotifications(state, notifications) {
            state.notifications = notifications
        },
        archiveNotificationLocal(state, notificationId) {
            state.notifications = state.notifications.filter(notification => notification.id !== notificationId)
        },
        markNotificationsReadLocal(state) {
            for (const notification of state.notifications) {
                notification.seen = true
            }
        },
        setMessages(state, messages) {
            state.messages = messages
        },
        setRecipients(state, recipients) {
            state.recipients = recipients
        },
        setThreads(state, threads) {
            state.threads = threads
        },
        setNotificationTokens(state, notificationTokens) {
            state.notificationTokens = notificationTokens
        },
    },
    actions: {
        fetchNotifications(context) {
            RequestAPI('get', "notifications", "", true).then(r => {
                if (r.data) {
                    context.commit("setNotifications", r.data)
                }
            })
        },
        fetchRecipients(context) {
            RequestAPI('get', "messages/recipients", "", true).then(r => {
                if (r.data) {
                    context.commit("setRecipients", r.data)
                }
            })
        },
        sendResponseMessage(context, payload) {
            RequestAPI('post', "messages/threads/" + payload.respondId, payload.message, true).then(r => {
                context.dispatch("fetchThreads")
            })
        },
        sendNewMessage(context, payload) {
            RequestAPI('post', "messages/threads", payload.message, true).then(r => {
                context.dispatch("fetchThreads")
            })
        },
        markMessageRead(context, payload) {
            RequestAPI('put', "messages/" + payload.respondId, payload.message, true).then(r => {
                context.dispatch("fetchMessages")
            })
        },
        fetchThreads(context) {
            RequestAPI('get', "messages/threads", "", true).then(r => {
                if (r.data) {
                    context.commit("setThreads", r.data)
                }
            })
        },
        saveNotificationToken(context, payload) {
            RequestAPI('post', "notifications/token", payload, true).then(r => {
                if (r.data) {
                    context.dispatch("listNotificationTokens")
                }
            })
        },
        listNotificationTokens(context) {
            RequestAPI('get', "notifications/token", "", true).then(r => {
                if (r.data) {
                    context.commit("setNotificationTokens", r.data)
                }
            })
        },
        archiveNotification(context, payload) {
            let notificationId = context.state.notifications.find(notification => notification.id === payload.notificationId).id

            const requestNotification = {
                id: notificationId,
                archived: true
            }

            RequestAPI('put', "notifications", requestNotification, true).then(r => {
                context.dispatch("fetchNotifications")
            })

            context.commit("archiveNotificationLocal", payload.notificationId)
        },
        markNotificationsRead(context) {
            for (const notification of context.state.notifications) {
                const requestNotification = {
                    id: notification.id,
                    seen: true
                }

                RequestAPI('put', "notifications", requestNotification, true).then(r => {
                    context.commit("markNotificationsReadLocal")
                })
            }
        }
    },
    getters: {
        getThreads(state) {
            let threads = []
            if (!state.messages) return threads
            for (const message of state.messages) {
                const thread = threads.find(thread => thread.id === message.id_thread)
                if (!thread) {
                    threads.push({
                        id: message.id_thread,
                        title: message.subject,
                        avatar: message.sender.avatar,
                        active: !message.seen,
                        lastMessageText: message.text
                    })
                } else {
                    if (!message.seen) {
                        thread.active = true
                        thread.lastMessageText = message.text
                    }
                }
            }
            return threads
        },
        getThreadById(state) {
            return (id) => state.threads.find(thread => thread.id_thread === id)
        },
        getUnseenNotificationNumber(state) {
            return state.notifications.filter(notification => notification.seen === false).length
        }
    }
}

export default settings
