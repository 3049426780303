import RequestAPI from "@/tools/request/v2";
import {calculateCPM} from "@/tools/acrobatics";
import {groupData, sumArray} from "@/tools/reportChartTools";

const reports = {
    state: () => ({
        metadata: null,
        report: null,
        reportError: false,
        historyToFetch: null,
        subscriptions: [],
        reportChartLoaded: false
    }),
    mutations: {
        setReport(state, payload) {
            Object.freeze(payload)
            state.report = payload
        },
        setError(state) {
            state.reportError = true;
        },
        clearError(state) {
            state.reportError = false;
        },
        clearReport(state) {
            state.report = null
        },
        setMetadata(state, payload) {
            state.metadata = payload
        },
        setHistoryToFetch(state, payload) {
            state.historyToFetch = payload
        },
        setSubscriptions(state, payload) {
            state.subscriptions = payload
        }

    },
    actions: {
        fetchMetadata(context) {
            RequestAPI('get', "reports", "", true).then(r => {
                if (r.data) {
                    context.commit("setMetadata", r.data)
                }
            })
        },
        fetchAPIReport(context, data) {
            RequestAPI('post', "reports", data, true).then(r => {
                if (r.data) {
                    context.commit("setReport", r.data)
                    context.commit("clearError")
                }
            }).catch(r => {
                context.commit("setError")
            })
        },
        createSubscription(context, data) {
            RequestAPI('post', "reports/subscriptions", data, true).then(r => {
                if (r.data) {
                    context.dispatch("fetchSubscriptions")
                }
            })
        },
        fetchSubscriptions(context) {
            RequestAPI('get', "reports/subscriptions", "", true).then(r => {
                if (r.data) {
                    context.commit("setSubscriptions", r.data)
                }
            })
        },
        updateSubscription(context, subscription) {
            RequestAPI('put', `reports/subscriptions`, subscription, true).then(r => {
                if (r.data) {
                    console.log(r.data)
                    context.dispatch("fetchSubscriptions")
                }
            })
        },
    },
    getters: {
        getReport(state) {
            return state.report
        },
        getMetadata(state) {
            return state.metadata
        },
        getError(state) {
            return state.reportError
        },
        userHasReportAccess(state) {
            return state.metadata !== null
        },
        getHistoryToFetch(state) {
            return state.historyToFetch
        },
        getSubscriptions(state) {
            return state.subscriptions
        },
        isReportChartLoaded: (state, getters) => {
            return state.reportChartLoaded
        },
        getDimensionsForCurrentReport: (state, getters) => {
            let report = getters.getReport
            if (!report) return
            const indices = report[0]
            const templates = getters.getMetadata.templates
            let allDimensions = new Set()
            for (const [key, value] of Object.entries(templates)) {
                for (const dim of value.dimensions) {
                    allDimensions.add(dim)
                }
            }
            return indices.filter(index => allDimensions.has(index.name)).map(index => index.name)
        },
        getMetricsForCurrentReport: (state, getters) => {
            let report = getters.getReport
            if (!report) return
            const indices = report[0]
            const templates = getters.getMetadata.templates
            let allMetrics = new Set()
            for (const [key, value] of Object.entries(templates)) {
                for (const metric of value.metrics) {
                    allMetrics.add(metric)
                }
            }
            return indices.filter(index => allMetrics.has(index.name)).map(index => index.name)
        },

        getReportChartData: (state, getters) => {
            console.time("report chart time")

            const report = getters.getReport;
            if (!report) return;

            const indices = report[0];
            const data = report.slice(1);

            const metrics = getters.getMetricsForCurrentReport
            const dimensions = getters.getDimensionsForCurrentReport

            const findHeaderIndex = (value) => {
                return indices.findIndex((e) => e.name === value)
            }

            const reportChartData = {
                date: [],
                values: []
            }


            // converting data as needed for chart
            for (const rowData of data) {
                if (!reportChartData.date.includes(rowData[0])) {
                    reportChartData.date.push(rowData[0])
                }

                const entryIndex = reportChartData.values.findIndex(entry => {
                    return dimensions.every(dimension => entry[dimension] === rowData[findHeaderIndex(dimension)])
                })


                if (entryIndex === -1) {
                    //not in the list
                    const newEntry = {}
                    for (const dimension of dimensions) {
                        newEntry[dimension] = rowData[findHeaderIndex(dimension)]
                    }
                    for (const metric of metrics) {
                        newEntry[metric] = {[rowData[0]]: rowData[findHeaderIndex(metric)]}
                    }
                    reportChartData.values.push(newEntry)
                } else {
                    for (const metric of metrics) {
                        reportChartData.values[entryIndex][metric][rowData[0]] = rowData[findHeaderIndex(metric)]
                    }
                }
            }

            //totaling each entries metric to sort them later by individual total
            for (const entry of reportChartData.values) {
                const tempMetrics = {}
                let localTotal = {}
                for (const metric of metrics) {
                    tempMetrics[metric] = [];
                    localTotal[metric] = 0
                }

                for (const currentDate of reportChartData.date) {
                    for (const metric of metrics) {
                        if (!entry[metric][currentDate]) {
                            tempMetrics[metric].push(0);
                        } else {
                            tempMetrics[metric].push(entry[metric][currentDate]);
                            localTotal[metric] += entry[metric][currentDate];
                        }
                    }
                }

                entry.total = {}
                for (const metric of metrics) {
                    entry[metric] = tempMetrics[metric];
                    entry.total[metric] = localTotal[metric];
                }
            }

            console.log("reportChartData pre", reportChartData)
            console.timeEnd("report chart time")
            return reportChartData

        },
    }
}

export default reports
