import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/es5/locale/de'
import en from 'vuetify/es5/locale/en'
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify);

// This will register the vuetify plugin. We can adjust themes here - other styles are adjusted using the /src/scss/variables.scss

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#00586C',
                accent: '#F77E47'
                // primary: '#00E87E'
            },
            dark: {
                primary: '#00586C'
            }
        }
    },
    lang: {
        locales: {de, en, es},
        current: 'de',
    },
});
