
<v-menu

    :disabled="disabled"
    ref="dateMenu"
    v-model="dialog"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-x
    left
    min-width="auto"
    v-if="timerangePresets.length > 0"
>
  <template v-slot:activator="{ on, attrs }">
    <v-text-field
        :dark="dark"
        flat
        solo-inverted
        single-line

        dense
        :label="$t('reports-timeframe-label')"
        v-model="dateDisplay"
        prepend-icon="mdi-calendar"
        readonly
        hide-details
        v-bind="attrs"
        v-on="on"
        rounded

    ></v-text-field>
  </template>

  <v-card flat max-width="40em">
    <v-row>
      <v-col class="mt-3 ml-3" >
        <v-card-title>
          {{title}}
        </v-card-title>
        <v-card-subtitle>
          {{subtitle}}
        </v-card-subtitle>
        <v-date-picker
            full-width
            no-title
            v-model="selectedTimerange"
            range
            @input="selectedPreset = null"
            show-adjacent-months
            :first-day-of-week="1"
            color="primary"
            class="mt-5"

        >

        </v-date-picker>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="5" >
        <v-card flat class="overflow-auto preset-bar-vertical">

          <v-list-item-group
              class="pt-5"
              v-model="selectedPreset"
          >
            <v-list-item
                v-for="preset in timerangePresets"
                :key="preset.name"
                dense
                color="primary"

            >
              <v-list-item-content>
                <v-list-item-title>
                  {{$t(preset.name)}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-divider></v-divider>
      </v-col>

    </v-row>



    <v-card-actions>

      <v-spacer></v-spacer>
      <v-btn
          text
          color="secondary"
          @click="dialog = false"
      >

        {{$t('button-cancel')}}
      </v-btn>


      <v-btn
          text
          color="primary"
          @click="apply"
          :disabled="!timeframeValid"
      >
        {{$t('button-ok')}}
      </v-btn>

    </v-card-actions>
  </v-card>
</v-menu>
