import firebase from "firebase/app";
import "firebase/auth"

let g_localPath = "";

export function RequestAPI(method, apifunction, params, authenticated, blob) {

    g_localPath = "https://rest.publisher.zone/";
    return new Promise((resolve, reject) => {

        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
            let data;
            if (!this.response && this.status === 202) {
                resolve("")
            }
            if (this.status === 502) reject(data)
            try {
                if (blob) {
                    // console.dir(this)
                    // data = new Blob([this.responseText], {type: "application/pdf"})
                    data = this.response
                    resolve(data)
                } else {
                    data = JSON.parse(this.responseText);
                }
            } catch (e) {
                if (!this.response) reject(data)
                data = {'error': 'JSON-Error'};
                data['errorText'] = this.responseText;
                resolve(data);
            }
            if (data['error'] === undefined)
                resolve(data);
            else
                reject(data);
        };
        xhr.onerror = function (e) {
            let data = {error: "XHR-Error"}
            data['errorText'] = this.statusText;
            reject(data);
        }
        xhr.onabort = function () {
            let data = {error: "XHR-Abort"}
            data['errorText'] = this.statusText;
            reject(data);
        };
        xhr.open(method, g_localPath + apifunction, true); // true = non-bnlocking
        xhr.setRequestHeader("Content-type", "application/json");
        if (blob) xhr.responseType = "blob"

        if (authenticated && firebase.auth().currentUser) {
            firebase.auth().currentUser.getIdToken().then((idToken => {
                // console.log(idToken)
                xhr.setRequestHeader("token", idToken);
                // xhr.setRequestHeader("D38ugU23r", "1");
                // xhr.setRequestHeader("d3bU9", "1");

                let datain = {};
                if (method !== 'get')
                    datain = params;
                xhr.send(JSON.stringify(datain));
            }))
        } else {
            let datain = {};
            if (method !== 'get')
                datain['params'] = params;
            xhr.send(JSON.stringify(datain));
        }
    });
}

export function verifyToken(idToken) {
    g_localPath = "http://localhost/Playground/daniel/pubzone/public/api/verify.php";
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
            let data;
            try {
                data = JSON.parse(this.responseText);
            } catch (e) {
                data = {'error': 'JSON-Error'};
                data['errorText'] = this.responseText;
                reject(data);
            }
            if (data['error'] === undefined)
                resolve(data);
            else
                reject(data);
        };
        xhr.onerror = function (e) {
            let data = {error: "XHR-Error"}
            data['errorText'] = this.statusText;
            reject(data);
        }
        xhr.onabort = function () {
            let data = {error: "XHR-Abort"}
            data['errorText'] = this.statusText;
            reject(data);
        };
        xhr.open('post', g_localPath, true); // true = non-bnlocking
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

        let datain = {};
        datain['idtoken'] = idToken;
        xhr.send(JSON.stringify(datain));
    });
}

export default RequestAPI;



