
  <div>
    <v-card>
      <v-navigation-drawer
          app
          class="sidebar"
          right
          v-model="rightDrawer"
          width="22em"
          color="primary"
          dark
      >
        <v-sheet class="gradient pa-2 mb-2">
          <div class="px-4">
            <np-date-picker dark v-model="selectedTimeframe" :timerange-presets="$store.getters.getTimeframePresets" @input="$store.dispatch('uploadFilter')"></np-date-picker>
          </div>
          <v-divider class="my-4"></v-divider>
          <v-checkbox hide-details :disabled="!sidebarReady" class="px-4" @click="compareChartToggle" v-model="compareChartEnabled" :label="$t('enable-compare-chart')"></v-checkbox>
          <v-checkbox hide-details  v-if="$store.getters.userHasReportAccess" :disabled="!sidebarReady" class="px-4" v-model="sourceCompareChartEnabled" :label="$t('enable-source-compare-chart')"></v-checkbox>

          <v-divider class="my-4"></v-divider>
          <v-icon class="pl-5">mdi-list-status</v-icon>
          <v-btn-toggle
              :disabled="!sidebarReady"
              class="ml-2 "
              v-model="sidebarMetricSelects"
              rounded
              dense
              multiple
              id="t_split"
          >

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn

                    color="#00303b"
                    v-on="on"
                    v-bind="attrs"
                    @input="afterMetricChange"
                >
                  <v-icon>mdi-web</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('sidebar-tooltip-show-websites') }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="#00303b"
                    v-on="on"
                    v-bind="attrs"
                    @input="afterMetricChange"
                >
                  <v-icon>mdi-cellphone-link</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('sidebar-tooltip-show-devices') }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="#00303b"
                    v-on="on"
                    v-bind="attrs"
                    @input="afterMetricChange"
                >
                  <v-icon>mdi-tab-unselected</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('sidebar-tooltip-show-adunits') }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="#00303b"
                    v-on="on"
                    v-bind="attrs"
                    @input="afterMetricChange"
                >
                  <v-icon>mdi-details</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('sidebar-tooltip-show-campaigns') }}</span>
            </v-tooltip>


          </v-btn-toggle>
          <v-divider class="my-4"></v-divider>
          <v-select hide-details :disabled="!sidebarReady" class="px-4" color="#00303B" prepend-icon="mdi-image-size-select-small" dense rounded flat solo-inverted :label="$t('granularity-selector')" :items="granularities" v-model="selectedGranularity"></v-select>
          <v-divider class="my-4"></v-divider>
          <!--          <v-autocomplete-->
<!--              id="t_timesel"-->
<!--              dark-->
<!--              class="mt-12 mb-2"-->
<!--              v-model="timeframeValue"-->
<!--              :items="$store.getters.getTimeframeSelectItems"-->
<!--              outlined-->
<!--              hide-details-->
<!--              dense-->
<!--              :label="this.$t('label-timeframe')"-->
<!--              @input="$store.dispatch('uploadFilter')"-->
<!--          >-->

<!--          </v-autocomplete>-->





<!--          <v-text-field-->
<!--              dense-->
<!--              v-model="search"-->
<!--              :label="this.$t('label-search')"-->
<!--              dark-->
<!--              rounded-->
<!--              flat-->
<!--              solo-inverted-->
<!--              hide-details-->
<!--              prepend-icon="mdi-magnify"-->
<!--              class="px-4"-->
<!--          ></v-text-field>-->

<!--          <v-tooltip top>-->
<!--            <template v-slot:activator="{ on, attrs }">-->
<!--              <v-btn-->
<!--                  icon-->
<!--                  class="ml-5"-->
<!--                  v-on="on"-->
<!--                  v-bind="attrs"-->
<!--                  @click="treeviewOpen = []"-->
<!--              >-->
<!--                <v-icon>mdi-arrow-u-up-right-bold</v-icon>-->
<!--              </v-btn>-->
<!--            </template>-->
<!--            <span>{{ $t('sidebar-close-all') }}</span>-->
<!--          </v-tooltip>-->


          <v-row no-gutters>
            <v-col cols="10">
              <v-text-field
                  dense
                  v-model="search"
                  :label="this.$t('label-search')"
                  dark
                  rounded
                  flat
                  solo-inverted
                  hide-details
                  prepend-icon="mdi-magnify"
                  class="pl-5"
              ></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon

                      v-on="on"
                      v-bind="attrs"
                      @click="treeviewOpen = []"
                  >
                    <v-icon>mdi-arrow-u-up-right-bold</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('sidebar-close-all') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>









        <v-treeview
            class="pt-1"
            color="primary"
            selected-color="primary lighten-2"
            id="t_filter"
            v-if="$store.state.statistics.dashboardReady"
            :open="treeviewOpen"
            open-on-click
            v-model="sidebarFilterSelects"
            dense
            :items="filterItems"
            selectable
            :search="search"

        >
        </v-treeview>

        </v-sheet>
        <v-skeleton-loader
            v-show="!$store.state.statistics.dashboardReady"
            class="mx-auto"
            max-width="300"
            height="800"
            type="image@3"
        ></v-skeleton-loader>


      </v-navigation-drawer>
    </v-card>
    <div class="text-center">
      <v-dialog
          v-model="customDateDialog"
          width="500"
      >

        <v-card class="pa-4">
          <v-card-title>
            {{ $t('sidebar-custom-date-title') }}
          </v-card-title>

          <v-date-picker
              no-title
              full-width
              v-model="customTimeframe"
              :first-day-of-week="1"
              range
              :max="moment().format('YYYY-MM-DD')"
          ></v-date-picker>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="applyCustomTimeframe()"
            >
              Apply
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-container v-show="!boxSelected">
      <v-alert type="warning">{{ $t("error-dashboard-no-dimension-selected") }}</v-alert>
    </v-container>
  </div>
