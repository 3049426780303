
<div ref="guide" class="guide-container elevation-6 rounded" style="max-width: 400px" v-if="!$store.getters.getCompletedTour">
  <div id="arrow" data-popper-arrow></div>
  <div id="guide">
    <v-card-title>{{current.title}}</v-card-title>
    <v-card-text v-for="text in current.content">{{text}}</v-card-text>
    <v-card-actions>
      <v-btn v-show="max > $store.getters.getCurrentTourStep" @click="next">{{$t("next")}}</v-btn>
      <v-btn v-show="max <= $store.getters.getCurrentTourStep" @click="end">{{$t("end_tour")}}</v-btn>
    </v-card-actions>
  </div>

</div>
