import RequestAPI from "@/tools/request/v2";
import moment, {locale} from "moment";
import {calculateCPM, formatCurrency, formatNumber, objectMap, arraysEqual, renameKeys} from "@/tools/acrobatics";
import XLSX from "xlsx"

const statistics = {
    state: () => ({
        domains: [],
        sources: [],
        customTimeframe: [],
        compareChartTimeframe: {},
        compareChartData: [],
        showCompareChart: false,
        showSourceCompareChart: true,
        dashboardReady: false,
        sidebarReady: false,
        sidebarFilterSelects: [],
        sidebarMaxFilters: 0,
        sidebarMetricSelects: [],
        sidebarDrawer: true,
        sidebarDimensionsSelected: true,
        selectedGranularity: "day",

        // domainFilter: [],
        deviceFilter: [
            {name: "Desktop"},
            {name: "Smartphone"},
            {name: "Tablet"},
            {name: "Connected TV"},
            {name: "Unknown"},
        ],
        adunitFilter: [],
        adunitDomain: null,
        report: [],
        sevenDayReport: {},
        timeframe: {
            value: "lastWeek",
            customValue: [],
        },
        selectedTimeframe : {
            start: null,
            end: null,
        },
        noAccountLink: false,
        noData: false,
        comboChartToolboxMetricsShown: {
            i: false,
            r: false,
            cpm: false,
        },
        advancedPieChartPrimarySwitch: false
    }),
    mutations: {
        setSidebarReady(state, sidebarReady) {
            state.sidebarReady = sidebarReady
        },
        setDomains(state, domains) {
            Object.freeze(domains)
            state.domains = domains
        },
        setSources(state, sources) {
            state.sources = sources
        },
        setSelectedTimeframe(state, timeframe) {
            state.selectedTimeframe = timeframe
        },
        setDashboard(state, dashboard) {
            Object.freeze(dashboard)
            state.report = dashboard
        },
        setTimeframeValue(state, value) {
            state.timeframe.value = value
        },
        setTimeframe(state, timeframe) {
            state.timeframe = timeframe
        },
        setNoAccountLink(state, noAccountLink) {
            state.noAccountLink = noAccountLink
        },
        setNoData(state, noData) {
            state.noData = noData
        },
        setSidebarDrawer(state, sidebarDrawer) {
            state.sidebarDrawer = sidebarDrawer
        },
        setSidebarMaxFilters(state, sidebarMaxFilters) {
            state.sidebarMaxFilters = sidebarMaxFilters
        },
        setSidebarDimensionsSelected(state, sidebarDimensions) {
            state.sidebarDimensionsSelected = sidebarDimensions
        },
        setComboChartToolboxMetricsShown(state, comboChartToolboxMetricsShown) {
            state.comboChartToolboxMetricsShown = comboChartToolboxMetricsShown
        },
        setAdvancedPieChartPrimarySwitch(state, advancedPieChartPrimarySwitch) {
            state.advancedPieChartPrimarySwitch = advancedPieChartPrimarySwitch
        },
        // generateFilters(state) {
        //     //Domains
        //     state.domainFilter = state.domains.map(domain => {
        //         return {domainId: domain.id, name: domain.name}
        //     })
        //
        //     // for (let i = 0; i < state.report.length; i++) {
        //     //     if (state.deviceFilter.findIndex(d => d.name === state.report[i].device) === -1) {
        //     //         state.deviceFilter.push({name: state.report[i].device})
        //     //     }
        //     // }
        // },
        preselectSidebar(state) {
            let temparray = []

            if (state.deviceFilter.length > 0) {
                for (const deviceFilterElement of state.deviceFilter) {
                    temparray.push(deviceFilterElement.name)
                }

                for (const domain of state.domains) {
                    if (!state.sidebarFilterSelects.includes(domain.id)) {
                        temparray.push(domain.id)
                    }
                    for (const adunit of domain.adunits) {
                        if (!state.sidebarFilterSelects.includes(adunit.id)) {
                            temparray.push(adunit.id)
                        }
                    }
                }

                for (const sourceKey in state.sources) {
                    const source = state.sources[sourceKey]
                    for (const campaignKey in source) {
                        temparray.push(campaignKey)
                    }
                }

                state.sidebarFilterSelects = state.sidebarFilterSelects.concat(temparray)
                state.dashboardReady = true;
            }


        },
        setDomainFilter(state, domainFilter) {
            state.domainFilter = domainFilter
        },
        setDeviceFilter(state, deviceFilter) {
            state.deviceFilter = deviceFilter
        },
        setAdunitFilter(state, adunitFilter) {
            state.adunitFilter = adunitFilter
        },
        setAdunitDomain(state, adunitDomain) {
            state.adunitDomain = adunitDomain
        },
        setSidebarFilterSelects(state, filters) {
            state.sidebarFilterSelects = filters
        },
        setSidebarMetricSelects(state, metrics) {
            state.sidebarMetricSelects = metrics
        },
        setCustomTimeframe(state, timeframe) {
            state.customTimeframe = timeframe.sort()
        },
        setCompareChartTimeframe(state, compareChartTimeframe) {
            state.compareChartTimeframe = compareChartTimeframe
        },
        setCompareChartData(state, compareChartData) {
            state.compareChartData = compareChartData
        },

        setShowCompareChart(state, bool) {
            state.showCompareChart = bool
        },
        setShowSourceCompareChart(state, bool) {
            state.showSourceCompareChart = bool
        },
        setSelectedGranularity(state, granularity) {
            state.selectedGranularity = granularity
        }

    },
    actions: {
        fetchDomains(context) {
            RequestAPI('get', "dashboard", "", true).then(r => {
                if (r.data) {
                    context.commit("setDomains", r.data.domains)
                    context.commit("setSources", r.data.sources)

                    const deviceFilters = r.data.devices.map(device => {
                        return {name: device}
                    })

                    context.commit("setDeviceFilter", deviceFilters)

                    context.dispatch("downloadFilter")
                }
                if (r.message === "Bad Request/No Data found") {
                    context.commit("setNoAccountLink", true)
                }
            }).catch(() => {
                context.commit("setNoAccountLink", true)
            })
        },
        downloadExportTable(context) {
            const getters = context.getters
            const workbook = XLSX.utils.book_new()
            // const table = getters.getTable.map(table => {
            //     return {
            //
            //     }
            // })
            const table = getters.getTable
            const translations = {
                website: getters.t("table-website"),
                date: getters.t("table-date"),
                i: getters.t("table-impressions"),
                r: getters.t("table-revenue"),
                cpm: getters.t("table-cpm"),
                adunit: getters.t("table-adunit"),
                device: getters.t("table-device"),
                campaign: getters.t("table-campaign"),
            }
            const result = table.map(x =>{
                return renameKeys(x, translations)
            })
            XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(result))
            XLSX.writeFile(workbook, 'pubzone-export.xlsx')
        },
        fetchCompare(context, timeframe){
            RequestAPI('post', "dashboard/reports/compare", timeframe, true).then(r => {
                context.commit("setCompareChartData", r.data)
            })
        },
        fetchReport(context) {
            context.commit("setSidebarReady", false)
            const timeframe = context.getters.getSelectedTimeframe
            if(!timeframe.start || !timeframe.end) {
                return
            }
            let metrics = []
            for (const entry of Object.entries(context.getters.getSelectedMetrics)) {
                if (entry[1]) {
                    if (entry[0] === "domains"){
                        metrics.push("website")
                    } else if (entry[0] === "adunits"){
                        metrics.push("adunit")
                    } else if (entry[0] === "devices"){
                        metrics.push("device")
                    } else if (entry[0] === "campaigns"){
                        metrics.push("campaign")
                    }
                }
            }
            // if (metrics.length === 0 && context.state.sidebarMaxFilters > context.state.sidebarFilterSelects.length) {
            //     metrics = ["website", "adunit", "device"]
            // }
            metrics.push("date")
            metrics.push("source")
            // if (context.getters.userHasReportAccess) {
            //     metrics.push("source")
            // }
            // metrics.push("website")
            // metrics.push("adunit")
            // metrics.push("device")

            const filter = {
            }
            const website = []
            const device = []
            const adunit = []
            const campaign = []


            const allAdunits = context.state.domains.map(domain => domain.adunits).flat()
            const allCampaigns = []

            for (const sourceKey in context.state.sources) {
                const source = context.state.sources[sourceKey]
                for (const campaignKey in source) {
                    allCampaigns.push(campaignKey)
                }
            }




            for (const filterSelect of context.state.sidebarFilterSelects) {
                if (context.state.domains.map(domain => domain.id).includes(filterSelect)) {
                    website.push(filterSelect)
                    continue;
                }
                if (context.state.deviceFilter.map(device => device.name).includes(filterSelect)) {
                    device.push(filterSelect)
                    continue;
                }
                if (allAdunits.map(adunit => adunit.id).includes(filterSelect)) {
                    adunit.push(filterSelect)
                    continue;
                }
                if (allCampaigns.includes(filterSelect)) {
                    campaign.push(filterSelect)
                    continue;
                }



            }

            if (website.length > 0) {
                filter.website = website
            }
            if (device.length > 0) {
                filter.device = device
            }
            if (adunit.length > 0) {
                filter.adunit = adunit
            }
            if (campaign.length > 0) {
                filter.source = campaign
            }

            if (filter === {}) return


            context.commit("setDashboard", [])
            // if (context.state.report.length > 0) return
            RequestAPI('post', "dashboard/reports/details", {
                ...context.getters.getSelectedTimeframe,
                dimensions: metrics,
                filter: filter,
                granularity: context.state.selectedGranularity
                // "start": context.getters.getStartDate,
                // "end": context.getters.getEndDate
            }, true).then(r => {
                if (r.data) {
                    context.commit("setNoAccountLink", false)
                    context.commit("setDashboard", r.data)
                    if (r.data.length === 0) {
                        context.commit("setNoData", true)
                    } else {
                        context.commit("setNoData", false)
                    }

                    // context.commit("generateFilters")
                    // context.commit("preselectSidebar")

                }
            }).catch(() => {
                context.commit("setNoAccountLink", true)
            })
        },
        uploadFilter(context) {
            const filter = {
                domains: context.getters.domainFilter,
                selected: context.state.sidebarFilterSelects,
                timeframe: context.state.timeframe,
                metrics: context.state.sidebarMetricSelects,
                customTimeframe: context.state.customTimeframe,
                comboChartToolboxMetricsShown: context.comboChartToolboxMetricsShown,
                selectedTimeframe: context.state.selectedTimeframe,
                showCompareChart: context.state.showCompareChart,
            }
            if (Object.keys(context.state.compareChartTimeframe).length !== 0){
                filter.compareChartTimeframe = context.state.compareChartTimeframe
            }

            RequestAPI('put', "tools/settings", filter, true).then(r => {
                if (r) {
                    RequestAPI('get', "tools/settings", "", true).then(r => {
                        if (r.data) {
                        }
                    })
                }
            })
        },
        downloadFilter(context) {

            RequestAPI('get', "tools/settings", "", true).then(r => {

                if (r.data) {
                    if (Object.keys(r.data).includes('selected') && Object.keys(r.data).includes('domains')) {
                        if (arraysEqual(r.data.domains, context.getters.domainFilter)) {
                            context.state.sidebarFilterSelects = r.data.selected
                            if (r.data.timeframe) context.commit("setTimeframe", r.data.timeframe)
                            if (r.data.customTimeframe) context.commit("setCustomTimeframe", r.data.customTimeframe)
                            if (r.data.showCompareChart) context.commit("setShowCompareChart", r.data.showCompareChart)
                            if (r.data.compareChartTimeframe){
                                context.commit("setCompareChartTimeframe", r.data.compareChartTimeframe)
                            }

                            if (r.data.metrics) context.commit("setSidebarMetricSelects", r.data.metrics)
                            if (r.data.comboChartToolboxMetricsShown) context.commit("setComboChartToolboxMetricsShown", r.data.comboChartToolboxMetricsShown)
                            context.state.dashboardReady = true;
                            context.dispatch("fetchReport")
                        } else {
                            context.commit("preselectSidebar")
                            context.dispatch("uploadFilter")
                            context.dispatch("fetchReport")

                        }
                    } else {
                        context.commit("preselectSidebar")
                        context.dispatch("uploadFilter")
                        context.dispatch("fetchReport")
                    }

                    if (r.data.showVendors){
                        context.commit("setShowVendors", r.data.showVendors)
                    }
                }
            })
        }
    },
    getters: {
        getSelectedTimeframe(state) {
            return state.selectedTimeframe
        },
        domainFilter(state, getters) {
            return state.domains.map(domain => {
                return {domainId: domain.id, name: domain.name}
            })
        },
        getCompareChartTimeframe(state, getters) {
            return state.compareChartTimeframe
        },
        getShowCompareChart(state, getters) {
            return state.showCompareChart
        },
        getShowSourceCompareChart(state, getters) {
            return state.showSourceCompareChart
        },
        getTimeframePresets(state, getters) {
            return [
                {name: getters.t("sidebar-yesterday"), source: "YESTERDAY"},
                {name: getters.t("sidebar-last7d"), source: "LAST_7_DAYS"},
                {name: getters.t("sidebar-last-week"), source: "LAST_WEEK"},
                {name: getters.t("sidebar-week-to-date"), source: "THIS_WEEK"},
                {name: getters.t("sidebar-last-month"), source: "LAST_MONTH"},
                {name: getters.t("sidebar-last30d"), source: "LAST_30_DAYS"},
                {name: getters.t("sidebar-month-to-date"), source: "THIS_MONTH"},
                {name: getters.t("sidebar-last90d"), source: "LAST_90_DAYS"},
                {name: getters.t("sidebar-last-year"), source: "LAST_YEAR"},
                {name: getters.t("sidebar-year-to-date"), source: "THIS_YEAR"},
            ]
        },
        getTableHeaders(state, getters) {
            let array = [
                {name: "table-date", value: "date", type: "date"},
                {name: 'table-source', value: "source", type: "string"},
                {name: 'table-impressions', value: "i", type: "int"},
                {name: 'table-revenue', value: "r", type: "currency"},
                {name: 'table-cpm', value: "cpm", type: "currency"}

            ]

            if (getters.getSelectedMetrics.adunits) array.splice(1, 0, {
                name: 'table-adunit',
                value: "adunit",
                type: "string"
            })
            if (getters.getSelectedMetrics.devices) array.splice(1, 0, {
                name: 'table-device',
                value: "device",
                type: "string"
            })
            if (getters.getSelectedMetrics.domains) array.splice(1, 0, {
                name:'table-website',
                value: "website",
                type: "string"
            })

            if (getters.getSelectedMetrics.campaigns) array.splice(1, 0, {
                name:'table-campaign',
                value: "campaign",
                type: "string"
            })

            return [array]
        },
        getTable(state, getters) {

            const table = []
            let dateKey = "date"

            for (const entry of getters.getFilteredReport) {

                let callback = x => {
                    return x[dateKey] === entry[dateKey] &&
                        (x.website === entry.website || !getters.getSelectedMetrics.domains) &&
                        (x.device === entry.device || !getters.getSelectedMetrics.devices) &&
                        (x.adunit === entry.adunit || !getters.getSelectedMetrics.adunits) &&
                        (x.campaign === entry.campaign || !getters.getSelectedMetrics.campaigns)
                }
                if (table.findIndex(callback) === -1) {
                    const adunitEntries = getters.getFilteredReport.filter(callback)
                    let i = 0;
                    let r = 0;
                    for (const adunitEntry of adunitEntries) {

                        i += adunitEntry.i;
                        r += adunitEntry.r;
                    }
                    const cpm = formatNumber(calculateCPM(i, r))
                    r = formatNumber(r)
                    let tableEntry = {
                        ...state.sidebarMetricSelects.includes(0) && {website: entry.website},
                        ...state.sidebarMetricSelects.includes(1) && {device: entry.device},
                        i: i,
                        r: r,
                        cpm: cpm,
                        ...state.sidebarMetricSelects.includes(2) && {adunit: entry.adunit},
                        campaign: entry.campaign,
                        source: entry.source
                    }
                    tableEntry[dateKey] = entry[dateKey]

                    table.push(tableEntry)
                }
            }


            // // add up all the values where date & source are the same
            // const sourceCompareData = []
            // for (const entry of table) {
            //     const index = sourceCompareData.findIndex(x => x.date === entry[dateKey] && x.source === entry.source)
            //     if (index === -1) {
            //         sourceCompareData.push({
            //             date: entry[dateKey],
            //             source: entry.source,
            //             i: entry.i,
            //             r: entry.r,
            //             cpm: entry.cpm,
            //         })
            //     } else {
            //         sourceCompareData[index].i += entry.i
            //         sourceCompareData[index].r += entry.r
            //     }
            // }
            //
            // state.sourceCompareChartData = sourceCompareData




            const mapped = table.map(x => {
                let date = moment(x.date)
                const array = [
                    date.format("DD.MM.YYYY"),
                    x.source,
                    x.i,
                    x.r,
                    x.cpm,
                ]
                if (state.sidebarMetricSelects.includes(2)) array.splice(1, 0, getters.getAdunitName(x.adunit))
                if (state.sidebarMetricSelects.includes(1)) array.splice(1, 0, x.device)
                if (state.sidebarMetricSelects.includes(0)) array.splice(1, 0, getters.getDomainName(x.website))
                if (state.sidebarMetricSelects.includes(3)) array.splice(1, 0, x.campaign)

                return array
            })




            state.sidebarReady = true

            return getters.getTableHeaders.concat(mapped)
        },
        getSelectedMetrics(state) {
            return {
                domains: state.sidebarMetricSelects.includes(0),
                devices: state.sidebarMetricSelects.includes(1),
                adunits: state.sidebarMetricSelects.includes(2),
                campaigns: state.sidebarMetricSelects.includes(3),
            }

        },
        getDomainName: (state) => (domainId) => {
            const domain = state.domains.find(domain => domain.id === domainId)
            if (domain) return domain.name;
            return domainId
        },
        getAdunitName: (state) => (adunitId) => {
            for (const domain of state.domains) {
                const found = domain.adunits.find(adunit => adunit.id === adunitId)
                if (found) return found.name
            }
        },
        getAdunit: (state) => (domainId, adunitId) => {
            const domain = state.domains.find(domain => domain.id === domainId)
            if (domain) {
                return domain.adunits.find(adunit => adunit.id === adunitId) || {name: adunitId}
            }
        },
        getIsSourceNetpointFilterEnabled(state) {
            let enabled = false
            for (const filter of state.sidebarFilterSelects) {
                try {
                    if (filter.split(":")[0] === "fda333722c361451fb25bfb4aa87a9f5") {
                        enabled = true
                    }
                } catch (e) {

                }
            }
            return enabled
        },
        getStartDate(state) {
            moment.updateLocale("en", {
                week: {
                    dow: 1, // First day of week is Monday
                    doy: 4  // First week of year must contain 4 January (7 + 1 - 4)
                }
            })
            if (state.timeframe.value === "custom") {
                if (state.customTimeframe.length === 2) {
                    return state.customTimeframe[0]
                } else {
                    return moment().subtract(1, "week").startOf("week").format("YYYY-MM-DD")
                }
            }

            if (state.timeframe.value === "lastMonth") {
                return moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD")
            }
            if (state.timeframe.value === "mtd") {
                if (moment().date() === 1) {
                    return moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD")
                }
                return moment().startOf("month").format("YYYY-MM-DD")
            }

            if (state.timeframe.value === "lastWeek") {
                return moment().subtract(1, "week").startOf("week").format("YYYY-MM-DD")
            }
            if (state.timeframe.value === "wtd") {
                if (moment().startOf("week").date() === moment().date()) {
                    return moment().subtract(2, "week").startOf("week").format("YYYY-MM-DD")
                } else {
                    return moment().startOf("week").format("YYYY-MM-DD")
                }
            }

            if (state.timeframe.value === "lastYear") {
                return moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD")
            }
            if (state.timeframe.value === "ytd") {
                return moment().startOf("year").format("YYYY-MM-DD")
            }

            return moment().subtract(state.timeframe.value, 'days').format("YYYY-MM-DD")

        },
        getEndDate(state) {
            if (state.timeframe.value === "custom") {
                if (state.customTimeframe.length === 2) {
                    return state.customTimeframe[1]
                } else {
                    return moment().subtract(1, "day").format("YYYY-MM-DD")
                }
            }
            if (state.timeframe.value === "lastWeek") {
                return moment().subtract(1, "week").endOf("week").format("YYYY-MM-DD")
            }
            if (state.timeframe.value === "lastMonth") {
                return moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
            }
            if (state.timeframe.value === "lastYear") {
                return moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD")
            }

            // if (state.timeframe.value === "wtd") {
            //     if (moment().startOf("week").date() === moment().date()){
            //         return moment().subtract(1, "week").endof.format("YYYY-MM-DD")
            //     }
            // }

            return moment().subtract(1, "day").format("YYYY-MM-DD")
        },
        getFormatedTimeframe(state, getters) {
            return moment(getters.getSelectedTimeframe.start).format("DD.MM.YYYY") + " - " + moment(getters.getSelectedTimeframe.end).format("DD.MM.YYYY")
        },
        getMetricSimple: (state, getters) => (metric) => {
            let simpleMetric = []
            let dateKey = "date"
            if (state.selectedGranularity !== "day") {
                dateKey = state.selectedGranularity
            }

            for (let i = 0; i < getters.getFilteredReport.length; i++) {
                const index = simpleMetric.findIndex(e => e.date === getters.getFilteredReport[i][dateKey])
                let value = getters.getFilteredReport[i][metric]

                if (index === -1) {
                    simpleMetric.push({date: getters.getFilteredReport[i][dateKey], v: value})
                } else {
                    simpleMetric[index].v += value
                }
            }
            return simpleMetric.map(e => formatNumber(e.v))

        },
        getCPMSimple: (state, getters) => {
            const revenue = getters.getMetricSimple('r')
            const impressions = getters.getMetricSimple('i')
            return revenue.map(r => formatNumber(r / impressions[revenue.indexOf(r)] * 1000))
        },
        getCPMTotal: (state, getters) => {
            const revenue = getters.getMetricSimple('r')
            const impressions = getters.getMetricSimple('i')

            if (revenue.length <= 0 || impressions.length <= 0) return

            const revenue_sum = revenue.reduce((a, b) => a + b)
            const impressions_sum = impressions.reduce((a, b) => a + b)

            return formatCurrency(calculateCPM(impressions_sum, revenue_sum))
        },
        getFilteredReport: (state) => {
            let result = state.report.filter(entry => {
                if (entry.source !== "netpoint") return false

                const dimensions = [
                    entry.website,
                    entry.device,
                    entry.adunit
                ]

                let active = false

                for (let i = 0; i < dimensions.length; i++) {
                    if (dimensions.every(dimension => dimension === undefined)){
                        return true
                    } else if (dimensions[i] != undefined) {
                        if (state.sidebarFilterSelects.includes(dimensions[i])) {
                            active = true
                        }
                    }

                }
                return true
            })

            const dateKey = state.selectedGranularity === "day" ? "date" : state.selectedGranularity

            if (state.selectedGranularity !== "day") {
                result = result.map(entry => {
                    if (state.selectedGranularity === "yearweek") {
                        const year = parseInt(entry[dateKey].split("-")[0])
                        const week = parseInt(entry[dateKey].split("-")[1])

                        const date = moment().year(year).week(week).startOf("week").format("YYYY-MM-DD")

                        return {
                            ...entry,
                            date: date
                        }

                    }
                    if (state.selectedGranularity === "yearmonth") {
                        const year = parseInt(entry[dateKey].split("-")[0])
                        let month = parseInt(entry[dateKey].split("-")[1])
                        month = month - 1

                        const date = moment().year(year).month(month).startOf("month").format("YYYY-MM-DD")

                        return {
                            ...entry,
                            date: date
                        }
                    }
                    if (state.selectedGranularity === "year") {
                        const year = parseInt(entry[dateKey].split("-")[0])
                        const date = moment().year(year).startOf("year").format("YYYY-MM-DD")

                        return {
                            ...entry,
                            date: date
                        }
                    }



                })
            }
            //formats: yearweek: "2023-11", yearmonth: "2023-03", year: "2023"


            return result.sort((a, b) => {
                if (a[dateKey] < b[dateKey]) return -1
                if (a[dateKey] > b[dateKey]) return 1
                return 0
            })

        },
        getFilteredReportFull: (state) => {
            let result = state.report.filter(entry => {

                const dimensions = [
                    entry.website,
                    entry.device,
                    entry.adunit
                ]

                let active = false

                for (let i = 0; i < dimensions.length; i++) {
                    if (dimensions.every(dimension => dimension === undefined)){
                        return true
                    } else if (dimensions[i] != undefined) {
                        if (state.sidebarFilterSelects.includes(dimensions[i])) {
                            active = true
                        }
                    }

                }
                return true
            })

            const dateKey = state.selectedGranularity === "day" ? "date" : state.selectedGranularity

            if (state.selectedGranularity !== "day") {
                result = result.map(entry => {
                    if (state.selectedGranularity === "yearweek") {
                        const year = parseInt(entry[dateKey].split("-")[0])
                        const week = parseInt(entry[dateKey].split("-")[1])

                        const date = moment().year(year).week(week).startOf("week").format("YYYY-MM-DD")

                        return {
                            ...entry,
                            date: date
                        }

                    }
                    if (state.selectedGranularity === "yearmonth") {
                        const year = parseInt(entry[dateKey].split("-")[0])
                        let month = parseInt(entry[dateKey].split("-")[1])
                        month = month - 1

                        const date = moment().year(year).month(month).startOf("month").format("YYYY-MM-DD")

                        return {
                            ...entry,
                            date: date
                        }
                    }
                    if (state.selectedGranularity === "year") {
                        const year = parseInt(entry[dateKey].split("-")[0])
                        const date = moment().year(year).startOf("year").format("YYYY-MM-DD")

                        return {
                            ...entry,
                            date: date
                        }
                    }



                })
            }
            //formats: yearweek: "2023-11", yearmonth: "2023-03", year: "2023"

            //reformat to DD.MM.YYYY

            // result = result.map(entry => {
            //     return {
            //         ...entry,
            //         date: moment(entry.date).format("DD.MM.YYYY")
            //     }
            // })

            return result.sort((a, b) => {
                if (a[dateKey] < b[dateKey]) return -1
                if (a[dateKey] > b[dateKey]) return 1
                return 0
            })

        },
        getSourceCompareData: (state, getters) => {
            const filteredReport = getters.getFilteredReportFull
            const sourceCompareData = []

            //dimensions do not apply to the source compare chart so have to be added up.
            //add up all the values where date & source are the same
            for (const entry of filteredReport) {
                const index = sourceCompareData.findIndex(x => x.date === entry.date && x.source === entry.source)
                if (index === -1) {
                    sourceCompareData.push({
                        date: entry.date,
                        source: entry.source,
                        i: entry.i,
                        r: entry.r,
                        cpm: entry.cpm,
                    })
                } else {
                    sourceCompareData[index].i += entry.i
                    sourceCompareData[index].r += entry.r
                    sourceCompareData[index].cpm += entry.cpm
                }
            }


            // turn into one array per source
            const sources = [...new Set(sourceCompareData.map(entry => entry.source))]

            const sourceCompareDataArray = []

            let dates = [...new Set(sourceCompareData.map(entry => entry.date))].sort((a, b) => new Date(a) - new Date(b));

            for (const source of sources) {
                const data = sourceCompareData.filter(entry => entry.source === source);
                const sourceCompareDataObject = {
                    date: [],
                    i: [],
                    r: [],
                    cpm: [],
                    source: source
                };

                for (const date of dates) {
                    const entry = data.find(entry => entry.date === date);
                    sourceCompareDataObject.date.push(date);
                    sourceCompareDataObject.i.push(entry ? entry.i : 0);
                    sourceCompareDataObject.r.push(entry ? entry.r : 0);
                    sourceCompareDataObject.cpm.push(entry ? entry.cpm : 0);
                }

                sourceCompareDataArray.push(sourceCompareDataObject);
            }

            //sort alphabetically
            sourceCompareDataArray.sort((a, b) => {
                if (a.source === "netpoint") return -1
                if (b.source === "netpoint") return 1
                return a.source.localeCompare(b.source)
            })


            return sourceCompareDataArray
        },
        getDimensions: (state, getters) => {
            let array = []
            if (getters.getSelectedMetrics.domains) array.push("website")
            if (getters.getSelectedMetrics.devices) array.push("device")
            if (getters.getSelectedMetrics.adunits) array.push("adunit")
            if (getters.getSelectedMetrics.campaigns) array.push("campaign")
            return array

        },

        getDimensionIndex: (state, getters) => (dimension) => {
           return  getters.getDimensions.indexOf(dimension)
        },

        getComboChartToolboxMetricsShown: (state) => {
            return state.comboChartToolboxMetricsShown
        },
        getAdvancedPieChartPrimarySwitch: (state) => {
            return state.advancedPieChartPrimarySwitch
        },
        getCompareChartData: (state, getters) => {
            let compareChartData = []
            const data = state.compareChartData
            if(!data[0]) return

            compareChartData.push(data[0].base)
            compareChartData.push(data[0].compare)
            for (let i = 0; i < compareChartData.length; i++) {
                for (let j = 0; j < compareChartData[i].date.length; j++) {

                    compareChartData[i].date[j] = moment(compareChartData[i].date[j]).format("DD.MM.YYYY")
                }
            }


            return compareChartData
        },
        getComboChartTotalData: (state, getters) => (dimensions) => {
            if (dimensions.length !== 0) return
            let tableData = getters.getTable.slice(1)
            let headers = getters.getTableHeaders[0]
            let comboChartData = {}
            const findHeaderIndex = (value) => {
                return headers.findIndex((e) => {
                    return  e.value === value
                })
            }
            comboChartData.date = []
            comboChartData.i = []
            comboChartData.r = []
            comboChartData.cpm = []
            comboChartData.values = []
            tableData = tableData.sort((b, a) => {
                return a[0] - b[0];
            })
            for (const data of tableData) {
                comboChartData.date.push(data[0])
                comboChartData.i.push(data[findHeaderIndex("i")])
                comboChartData.r.push(data[findHeaderIndex("r")])
                comboChartData.cpm.push(data[findHeaderIndex("cpm")])

            }

            return comboChartData
        },


        getComboChartData: (state, getters, rootState, rootGetters) => (dimensions) => {
            let headers = getters.getTableHeaders[0]
            const findHeaderIndex = (value) => {
                return headers.findIndex((e) => {
                    return  e.value === value
                })
            }


            let comboChartData = {}
            comboChartData.date = []
            comboChartData.values = []
            const table = getters.getTable.slice(1)

            state.noData = table.length === 0;

            for (const tableData of table) {

                if (!comboChartData.date.includes(tableData[0])) {
                    comboChartData.date.push(tableData[0])
                }
                let index = comboChartData.values.findIndex(entry => {
                    let matching = []

                    for (let i = 0; i < getters.getDimensions.length; i++) {
                        const dimension = getters.getDimensions[i]

                        matching[i] = entry[dimension] === tableData[findHeaderIndex(dimension)];
                    }

                    return matching.every(x => x === true)
                })

                if (index === -1) {
                    comboChartData.values.push({
                        ...state.sidebarMetricSelects.includes(0) && {website: tableData[findHeaderIndex("website")]},
                        ...state.sidebarMetricSelects.includes(1) && {device: tableData[findHeaderIndex("device")]},
                        i: {
                            [tableData[0]]: tableData[findHeaderIndex("i")]
                        },
                        r: {
                            [tableData[0]]: tableData[findHeaderIndex("r")]
                        },
                        cpm: {
                            [tableData[0]]: tableData[findHeaderIndex("cpm")]
                        },
                        ...state.sidebarMetricSelects.includes(2) && {adunit: tableData[findHeaderIndex("adunit")]},
                        ...state.sidebarMetricSelects.includes(3) && {campaign: tableData[findHeaderIndex("campaign")]},

                    })
                } else {
                    comboChartData.values[index].i[tableData[0]] = tableData[findHeaderIndex("i")]
                    comboChartData.values[index].r[tableData[0]] = tableData[findHeaderIndex("r")]
                    comboChartData.values[index].cpm[tableData[0]] = tableData[findHeaderIndex("cpm")]
                }


            }

            for (let i = 0; i < comboChartData.values.length; i++) {
                let tempImpressions = []
                let tempRevenue = []
                let tempCPM = []
                for (let j = 0; j < comboChartData.date.length; j++) {

                    if (!comboChartData.values[i].i[comboChartData.date[j]]) {
                        tempImpressions.push(0)
                    } else {
                        tempImpressions.push(comboChartData.values[i].i[comboChartData.date[j]])
                    }

                    if (!comboChartData.values[i].r[comboChartData.date[j]]) {
                        tempRevenue.push(0)
                    } else {
                        tempRevenue.push(comboChartData.values[i].r[comboChartData.date[j]])
                    }

                    if (!comboChartData.values[i].cpm[comboChartData.date[j]]) {
                        tempCPM.push(0)
                    } else {
                        tempCPM.push(comboChartData.values[i].cpm[comboChartData.date[j]])
                    }

                }
                comboChartData.values[i].i = tempImpressions
                comboChartData.values[i].r = tempRevenue
                comboChartData.values[i].cpm = tempCPM
            }


            for (let i = 0; i < comboChartData.values.length; i++) {
                let totalI = 0
                for (let [key, val] of Object.entries(comboChartData.values[i])){
                    if (key === "i"){
                        for (const entry of val) {
                            totalI += entry
                        }
                    }
                }
                comboChartData.values[i].TempI = totalI
            }

            comboChartData.values = comboChartData.values.sort((b, a) => {
                return a.TempI - b.TempI;
            })
            let totalImpressions;
            let totalRevenue;
            let totalCPM = [];
            comboChartData.data = []

            for (let i = 0; i < comboChartData.values.length; i++) {


                if (i < rootGetters.getChartThreshold){
                    comboChartData.data.push(comboChartData.values[i])
                }
                else{
                    if (!totalImpressions){
                        totalImpressions = comboChartData.values[i].i
                    }else {
                        totalImpressions = totalImpressions.map(function (num, idx) {
                            return num + comboChartData.values[i].i[idx];
                        })
                    }
                    if (!totalRevenue){
                        totalRevenue = comboChartData.values[i].r
                    }else {
                        totalRevenue = totalRevenue.map(function (num, idx) {
                            return num + comboChartData.values[i].r[idx];
                        })
                    }
                }

            }
            if (totalImpressions){
                for (let i = 0; i < totalImpressions.length; i++) {
                    totalCPM.push(calculateCPM(totalImpressions[i] ,totalRevenue[i]))
                }
            }


            comboChartData.data.push({
                website: "others",
                device: "others",
                adunit: "others",
                campaign: "others",
                i: totalImpressions,
                r: totalRevenue,
                cpm: totalCPM
            })


            comboChartData.values = comboChartData.data
            comboChartData.data = []
            return comboChartData

        },



        getLocaleString: (state, getters, rootState, rootGetters) => (input, options) =>{
            const locales = { de: "de-DE", en: "en-US"}

            return input.toLocaleString(locales[rootGetters.getSelectedLanguage.lang], options)
        },

        getLineChartData: (state, getters) => (metrics, dimensions) => {
            const tImpression = getters.t('metric-name-impressions')
            const tRevenue = getters.t('metric-name-revenue')
            const tECpm = getters.t('metric-name-ecpm')

            const filteredReport = getters.getFilteredReport
            let values = []

            for (const report of filteredReport) {
                const dateIndex = values.findIndex(e => e.date === report.date)
                if (dateIndex === -1) values.push({
                    date: report.date,
                    // ...metrics.includes("i") && {"Total Impressions": report.i},
                    // ...metrics.includes("r") && {"Total Revenue": report.r},
                    ...dimensions.includes("device") && {
                        ...metrics.includes("i") && {[tImpression + " " + report.device]: report.i},
                        ...metrics.includes("r") && {[tRevenue + " " + report.device]: report.r},
                    },
                    ...dimensions.includes("adunit") && {
                        ...metrics.includes("i") && {[report.adunit + "ওAdunitওI"]: report.i},
                        ...metrics.includes("r") && {[report.adunit + "ওAdunitওR"]: report.r},
                    },
                    ...dimensions.includes("website") && {
                        ...metrics.includes("i") && {[report.website + "ওWebsiteওI"]: report.i},
                        ...metrics.includes("r") && {[report.website + "ওWebsiteওR"]: report.r},
                    },


                })
                else {
                    // if (metrics.includes("i")) values[dateIndex]["Total Impressions"] += report.i
                    // if (metrics.includes("r")) values[dateIndex]["Total Revenue"] += report.r
                    if (dimensions.includes("device")) {
                        metrics.includes("i") && (values[dateIndex][tImpression + " " + report.device] ? values[dateIndex][tImpression + " " + report.device] += report.i : values[dateIndex][tImpression + " " + report.device] = report.i)
                        metrics.includes("r") && (values[dateIndex][tRevenue + " " + report.device] ? values[dateIndex][tRevenue + " " + report.device] += report.r : values[dateIndex][tRevenue + " " + report.device] = report.r)
                    }

                    if (dimensions.includes("adunit")) {
                        metrics.includes("i") && (values[dateIndex][report.adunit + "ওAdunitওI"] ? values[dateIndex][report.adunit + "ওAdunitওI"] += report.i : values[dateIndex][report.adunit + "ওAdunitওI"] = report.i)
                        metrics.includes("r") && (values[dateIndex][report.adunit + "ওAdunitওR"] ? values[dateIndex][report.adunit + "ওAdunitওR"] += report.r : values[dateIndex][report.adunit + "ওAdunitওR"] = report.r)

                    }

                    if (dimensions.includes("website")) {
                        metrics.includes("i") && (values[dateIndex][report.website + "ওWebsiteওI"] ? values[dateIndex][report.website + "ওWebsiteওI"] += report.i : values[dateIndex][report.website + "ওWebsiteওI"] = report.i)
                        metrics.includes("r") && (values[dateIndex][report.website + "ওWebsiteওR"] ? values[dateIndex][report.website + "ওWebsiteওR"] += report.r : values[dateIndex][report.website + "ওWebsiteওR"] = report.r)
                    }
                }
            }

            for (let i = 0; i < values.length; i++) {

                for (let [key, val] of Object.entries(values[i])) {

                    let name = key.split('ও')


                    if (name[1] && name[1] === "Adunit") {

                        if (name[2] === 'I') {
                            delete values[i][key]

                            values[i][tImpression + " " + getters.getAdunitName(parseInt(name[0]))] = val

                        }
                        if (name[2] === 'R') {
                            delete values[i][key]


                            values[i][tRevenue + " " + getters.getAdunitName(parseInt(name[0]))] = val
                        }
                    }
                    if (name[1] && name[1] === "Website") {
                        if (name[2] === 'I') {
                            delete values[i][key]
                            values[i][tImpression + " " + getters.getDomainName(parseInt(name[0]))] = val
                        }
                        if (name[2] === 'R') {
                            delete values[i][key]

                            values[i][tRevenue + " " + getters.getDomainName(parseInt(name[0]))] = val
                        }
                    }

                }


                if (metrics.includes("cpm")) {
                    const impressionsAndRevenues = getters.getLineChartData(["i", "r"], dimensions)

                    for (let impressionAndRevenue of impressionsAndRevenues) {
                        if (impressionAndRevenue.date === values[i].date) {
                            for (let [key, val] of Object.entries(impressionAndRevenue)) {
                                if (key.startsWith(tImpression + " ")) {
                                    let dimensionName = key.replace(tImpression + " ", "")
                                    let cpm = calculateCPM(impressionAndRevenue[tImpression + " " + dimensionName], impressionAndRevenue[tRevenue + " " + dimensionName])
                                    values[i][tECpm + " " + dimensionName] = cpm

                                }
                            }
                        }
                    }


                    // delete values[i][name[0] + name[1] + "R"]

                }


                values[i] = Object.fromEntries(
                    Object.entries(values[i]).sort(([, a], [, b]) => b - a)
                );


                Object.freeze(values[i])
            }

            return values
        },

        getLineChartTotalData: (state, getters) => (metrics, dimensions) => {
            let filteredReport = getters.getFilteredReport;


            let values = []

            for (const report of filteredReport) {
                const dateIndex = values.findIndex(e => e.date === report.date)
                if (dateIndex === -1) values.push({
                    date: report.date,
                    ...metrics.includes("i") && {"i": report.i},
                    ...metrics.includes("r") && {"r": report.r},
                })
                else {
                    if (metrics.includes("i")) values[dateIndex]["i"] += report.i
                    if (metrics.includes("r")) values[dateIndex]["r"] += report.r
                }


            }
            let totalValue = {}
            let totalDates = []
            let totalI = []
            let totalR = []
            let totalCpm = []
            for (let value of values) {
                for (let [key, val] of Object.entries(value)) {
                    if (key !== 'date') {
                        if (metrics.includes("i") && key === "i") {
                            totalI.push(val)
                        }
                        if (metrics.includes("r") && key === "r") {
                            totalR.push(val)
                        }

                    } else {

                        //totalDates.push(moment(val).format('D M YY').replaceAll(" ","."))
                        totalDates.push(val)
                    }

                }
                if (metrics.includes("cpm")) {
                    const impressionsAndRevenues = getters.getLineChartTotalData(["i", "r"], dimensions)

                    for (let i = 0; i < impressionsAndRevenues.values.i.length; i++) {
                        totalCpm.push(calculateCPM(impressionsAndRevenues.values.i[i], impressionsAndRevenues.values.r[i]))
                    }


                }


            }
            totalValue.i = totalI
            totalValue.r = totalR
            totalValue.cpm = totalCpm
            return {values: totalValue, dates: totalDates}
        },
        getMetricFull: (state, getters) => (metric) => {
            const filteredReport = getters.getFilteredReport
            if (filteredReport.length <= 0) return []
            let total = {name: 'Total', data: {}}
            let array = [total]
            for (let i = 0; i < filteredReport.length; i++) {

                // Total
                let value = filteredReport[i][metric]
                if (!total.data[filteredReport[i].date]) {
                    total.data[filteredReport[i].date] = value
                } else {
                    total.data[filteredReport[i].date] += value
                }

                if (getters.getSelectedMetrics.domains) {
                    const domain = getters.getDomainName(filteredReport[i].website)
                    const indexDomain = array.findIndex(e => e.name === domain)
                    if (indexDomain === -1) {
                        array.push({name: domain, data: {}})
                    } else {
                        if (!array[indexDomain].data[filteredReport[i].date]) {
                            array[indexDomain].data[filteredReport[i].date] = value
                        } else {
                            array[indexDomain].data[filteredReport[i].date] += value
                        }
                    }
                }


                if (getters.getSelectedMetrics.devices) {
                    const device = filteredReport[i].device
                    const indexDevice = array.findIndex(e => e.name === device)
                    if (indexDevice === -1) {
                        array.push({name: device, data: {}})
                    } else {
                        if (!array[indexDevice].data[filteredReport[i].date]) {
                            array[indexDevice].data[filteredReport[i].date] = value
                        } else {
                            array[indexDevice].data[filteredReport[i].date] += value
                        }
                    }
                }


                if (getters.getSelectedMetrics.adunits) {
                    const adunit = getters.getAdunitName(filteredReport[i].adunit)
                    const indexAdunit = array.findIndex(e => e.name === adunit)
                    if (indexAdunit === -1) {
                        array.push({name: adunit, data: {}})
                    } else {
                        if (!array[indexAdunit].data[filteredReport[i].date]) {
                            array[indexAdunit].data[filteredReport[i].date] = value
                        } else {
                            array[indexAdunit].data[filteredReport[i].date] += value
                        }
                    }
                }


            }


            return array
        },
        getCPMFull: (state, getters) => {
            if (state.report.length <= 0) return {}

            const revenue = getters.getMetricFull('r')
            const impressions = getters.getMetricFull('i')

            //calculate the effective CPM for each datapoint in every segment
            return revenue.map((segment, index) => {
                return {
                    //objectMap resembles Array.prototype.map()
                    name: segment.name,
                    data: objectMap(segment.data, (value, key) => {
                        const impressionV = impressions[index].data[key]
                        return formatNumber(value / impressionV * 1000)
                    })
                }
            })
        },
        getPieCharts: (state, getters) => (metric, dim) => {
            const filteredReport = getters.getFilteredReport
            let pieChartData = []

            if (metric === 'cpm') {
                const iPieData = getters.getPieCharts('i', dim)
                const rPieData = getters.getPieCharts('r', dim)

                for (let i = 0; i < iPieData.length; i++) {
                    const domain = iPieData[i][0]
                    const impressions = iPieData[i][1]
                    const revenue = rPieData[i][1]
                    pieChartData.push([domain, formatNumber(calculateCPM(impressions, revenue))])
                }
                return pieChartData
            }

            for (let i = 0; i < filteredReport.length; i++) {
                let categoryName = filteredReport[i][dim]
                if (dim === 'website') {
                    categoryName = getters.getDomainName(categoryName)
                }
                if (dim === 'adunit') {
                    categoryName = getters.getAdunitName(categoryName)
                }
                const index = pieChartData.findIndex(e => e[0] === categoryName)
                if (index > -1) {
                    pieChartData[index][1] += formatNumber(filteredReport[i][metric])
                } else {
                    pieChartData.push([categoryName, formatNumber(filteredReport[i][metric])])
                }
            }


            return pieChartData
        },
        getFancyPieCharts: (state, getters) => (metric, dim, filteredReport = getters.getFilteredReport) => {

            let pieChartData = []

            if (metric === 'cpm') {
                const iPieData = getters.getFancyPieCharts('i', dim)
                const rPieData = getters.getFancyPieCharts('r', dim)

                for (let i = 0; i < iPieData.length; i++) {
                    const domain = iPieData[i].name
                    const impressions = iPieData[i].value
                    const revenue = rPieData[i].value
                    pieChartData.push({
                        name: domain,
                        value: formatNumber(calculateCPM(impressions, revenue)),
                        id: iPieData[i].id
                    })
                }

                return pieChartData
            }

            for (let i = 0; i < filteredReport.length; i++) {
                let categoryName = filteredReport[i][dim]
                if (dim === 'website') {
                    categoryName = getters.getDomainName(categoryName)
                }
                if (dim === 'adunit') {
                    categoryName = getters.getAdunitName(categoryName)
                }
                const index = pieChartData.findIndex(e => e.name === categoryName)

                if (index > -1) {
                    pieChartData[index].value += formatNumber(filteredReport[i][metric])
                } else {
                    pieChartData.push({
                        name: categoryName,
                        value: formatNumber(filteredReport[i][metric]),
                        id: filteredReport[i][dim]
                    })
                }
            }

            return pieChartData
        },

        getFancyPieChartData: (state, getters) => (metric, primaryDimension, id) => {

            let filteredReport = getters.getFilteredReport;
            if (id) {
                filteredReport = filteredReport.filter(entry => {
                    if (primaryDimension === "device") return entry.device === id
                    if (primaryDimension === "website") return entry.website === id
                })
            }

            let devices;
            let website;
            if (primaryDimension !== "device") devices = getters.getFancyPieCharts(metric, "device", filteredReport)
            else devices = getters.getFancyPieCharts(metric, "device")

            const adunits = getters.getFancyPieCharts(metric, "adunit", filteredReport)

            if (primaryDimension !== "website") website = getters.getFancyPieCharts(metric, "website", filteredReport)
            else website = getters.getFancyPieCharts(metric, "website")

            return {primary: website, secondary: devices, tertiary: adunits}

        },


    }
}

export default statistics
