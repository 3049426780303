import firebase from "firebase/app";
import "firebase/auth"
import router from "@/router";

const auth = {
    state: () => ({
        credential: null,
        providers: {
            google: new firebase.auth.GoogleAuthProvider(),
            microsoft: new firebase.auth.OAuthProvider("microsoft.com")
        }
    }),
    mutations: {
        setCredential(state, credential) {
            state.credential = credential
        }
    },
    actions: {
        handlePostLogin(context) {


            console.log(firebase.auth().currentUser)
            if (firebase.auth().currentUser) {
                if (context.rootState.misc.inviteCode) {
                    context.dispatch("redeemInvitiation")
                }
            }

            window.location.href = "/"

        }
    },
    getters: {
        getProvider: (state) => (providerDomain) => {
            return state.providers[providerDomain.split('.')[0]]
        }
    }
}

export default auth
