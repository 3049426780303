import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import auth from "@/tools/auth";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: () => import('../views/Dashboard.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/auth/Register.vue')
    },
    {
        path: '/register/complete',
        name: 'CompleteRegistration',
        component: () => import( '../views/auth/CompleteRegistration.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import( '../views/auth/Login.vue')
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import( '../views/Settings.vue')
    },
    {
        path: '/account',
        name: 'Account',
        component: () => import('../views/Account.vue')
    },
    {
        path: '/documents',
        name: 'Documents',
        component: () => import('../views/Documents.vue')
    },
    {
        path: '/messages',
        name: 'Messages',
        component: () => import('../views/Messages.vue')
    },
    {
        path: '/privacy-policy',
        name: 'Privacy-policy',
        component: () => import('../views/PrivacyPolicy')
    },
    {
        path: '/messages/:threadId',
        name: 'MessagesThread',
        component: () => import('../views/Thread.vue')
    },
    {
        path: '/adstxt',
        name: 'AdsTxt',
        component: () => import('../views/AdsTxt.vue')
    },
    // {
    //   path: '/policy-violations',
    //   name: 'PolicyViolations',
    //   component: () => import(/* webpackChunkName: "policyViolations" */ '../views/PolicyViolations.vue')
    // },
    {
        path: '/disclosure',
        name: 'Disclosure',
        component: () => import(/* webpackChunkName: "disclosure" */ '../views/Disclosure.vue')
    },
    {
        path: '/tags',
        name: 'Tags',
        component: () => import(/* webpackChunkName: "tags" */ '../views/Tags.vue')
    },
    {
        path: '/invitation/:hash',
        name: 'Invitation',
        component: () => import(/* webpackChunkName: "invitation" */ '../views/auth/Invitation.vue')
    },
    {
        path: '/debug',
        name: 'Debug',
        component: () => import(/* webpackChunkName: "debug" */ '../views/debug.vue')
    },
    {
        path: '/reports',
        name: 'Reports',
        component: () => import(/* webpackChunkName: "debug" */ '../views/Reports.vue')
    },
    {
        path: '/reports/new',
        name: 'NewReport',
        component: () => import(/* webpackChunkName: "debug" */ '../views/NewReport.vue')
    },
    {
        path: '/reports/new/subscription',
        name: 'NewReportSubscription',
        component: () => import(/* webpackChunkName: "debug" */ '../views/NewReportSubscription.vue')
    },
    {
        path: '/partner',
        name: 'Partner',
        component: () => import(/* webpackChunkName: "debug" */ '../views/Partners.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: import.meta.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.name !== 'Login' && to.name !== 'Register' && to.name !== 'Invitation' && to.name !== 'Privacy-policy' && !auth.currentUser) {
        next({name: 'Login'})
    } else next()
})


export default router
