<template>
  <div ref="guide" class="guide-container elevation-6 rounded" style="max-width: 400px" v-if="!$store.getters.getCompletedTour">
    <div id="arrow" data-popper-arrow></div>
    <div id="guide">
      <v-card-title>{{current.title}}</v-card-title>
      <v-card-text v-for="text in current.content">{{text}}</v-card-text>
      <v-card-actions>
        <v-btn v-show="max > $store.getters.getCurrentTourStep" @click="next">{{$t("next")}}</v-btn>
        <v-btn v-show="max <= $store.getters.getCurrentTourStep" @click="end">{{$t("end_tour")}}</v-btn>
      </v-card-actions>
    </div>

  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
export default {
  name: "np-tour",
  props: {
    steps: Array,
    max: Number,
  },
  data() {
    return {
      popperInstance: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.updateGuide()
    })
  },
  computed: {
    current(){
      return this.steps[this.$store.getters.getCurrentTourStep]
    },
  },
  methods: {
    updateGuide(){
      if (this.$store.getters.getCompletedTour) return
      const display = this.$refs.guide
      const anchor = document.querySelector(this.current.target);
      this.$router.push({name: this.current.site})

      // console.log(display, anchor)
      this.popperInstance = createPopper(anchor, display, {
            placement: "auto-start",
            strategy: 'fixed',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          },
      );






      this.popperInstance.update()
      window.scrollBy(0,2)
      window.scrollBy(0,-2)
      window.scrollTo(anchor)
    },
    next(){
      this.$store.commit("incrementTourStep")
      this.updateGuide()
    },
    end(){
      this.$store.commit("completeTour")

    }
  }
}
</script>

<style scoped>
.guide-container{
  z-index: 10;
}
#guide {
  display: inline-block;
  background: #ffffff;
  padding: 5px 10px;
  border-radius: 4px;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
  color: #ffffff;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

#guide[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
}

#guide[data-popper-placement^='bottom'] > #arrow {
  top: -4px;
}

#guide[data-popper-placement^='left'] > #arrow {
  right: -4px;
}

#guide[data-popper-placement^='right'] > #arrow {
  left: -4px;
}

</style>
