import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import auth from "./tools/auth"
import Request from "./tools/request"
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import titleMixin from './mixins/titleMixin'



// Disable production Warning
Vue.config.productionTip = false

// make tools available to all components
Vue.prototype.$auth = auth
Vue.prototype.$request = Request
Vue.prototype.$t = store.getters.t

//Use chartkick vue plugin
Vue.use(Chartkick.use(Chart))

//Dynamic titles mixin
Vue.mixin(titleMixin)

/** Vue Filters Start */
Vue.filter('truncate', function (text, length, suffix) {
    if (text.length > length) {
        return text.substring(0, length) + suffix;
    } else {
        return text;
    }
});
/** Vue Filters End */



// make sure auth is ready and initialize vue
let app;
auth.onAuthStateChanged(user => {
    if (!app) {
        app = new Vue({
            router,
            store,
            vuetify,
            render: h => h(App)
        }).$mount("#app");
    }
});
