import firebase from "firebase/app";
import "firebase/auth"

export async function RequestAPI(method, apifunction, params, authenticated, blob) {
    const baseUrl = "https://rest.publisher.zone/";

    const headers = {
        "Content-type": "application/json"
    }

    if (authenticated && firebase.auth().currentUser) {
        headers["token"] = await firebase.auth().currentUser.getIdToken()
    }

    let init = {
        method: method,
        headers: headers
    }

    if (params) {
        init.body = JSON.stringify(params)
    }

    if (blob) {
        init.responseType = "blob"
    }

    const response = await fetch(baseUrl + apifunction, init)

    if (response.ok) {
        if (blob) {
            return await response.blob()
        } else {
            return await response.json()
        }
    }

    return null

}

export default RequestAPI
