import Vuetify from '../../plugins/vuetify'
import RequestAPI from "@/tools/request/v2";
import {v4 as uuidv4} from "uuid";

const settings = {
    state: () => ({
        darkTheme: null,
        showAdvancedPieChart: false,

        recieveNotifications: false,
        uuid: null,
        chartThreshold: 10,
        showVendors: false,

    }),
    mutations: {
        generateUUID(state) {
            if (state.uuid === null) {
                state.uuid = uuidv4();
            }
        },
        setDarkTheme(state, bool) {
            state.darkTheme = bool
            Vuetify.framework.theme.dark = bool
        },
        setShowAdvancedPieChart(state, bool) {
            state.showAdvancedPieChart = bool
            // Vuetify.framework.theme.dark = bool
        },

        setRecieveNotifications(state, bool) {
            state.recieveNotifications = bool
            // Vuetify.framework.theme.dark = bool
        },
        setShowVendors(state, bool) {
            state.showVendors = bool
        }

    },
    actions: {
        fetchSettings(context) {
            RequestAPI('get', "tools/settings", "", true).then(r => {
                if (r.data) {
                    console.log(r.data)
                    if (Object.keys(r.data).length < 3)
                        context.dispatch("saveSettings")
                }
            })
        },
        saveSettings(context) {
            RequestAPI('post', "tools/settings", context.state, true).then(r => {
                if (r) {
                    context.dispatch("fetchSettings")
                }
            })
        },
        saveSetting(context, setting) {
            RequestAPI('put', "tools/settings", setting, true).then(r => {
                if (Object.keys(setting).includes("darkTheme")) {
                    context.commit("setDarkTheme", setting.darkTheme)
                }
                if (Object.keys(setting).includes("showAdvancedPieChart")) {
                    context.commit("setShowAdvancedPieChart", setting.showAdvancedPieChart)
                }
                if (Object.keys(setting).includes("recieveNotifications")) {
                    context.commit("setRecieveNotifications", setting.recieveNotifications)
                }
                if (r) {
                    context.dispatch("fetchSettings")
                }
            })
        }

    },
    getters: {
        getChartThreshold:(state) => {
            return state.chartThreshold
        },
        getShowVendors:(state) => {
            return state.showVendors
        }
    }
}

export default settings
